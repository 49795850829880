import { apiGetWithToken } from "../../services/api";
import { PATH_LOAN } from "../../services/path/loan";


async function getLoanList(props) {
  let params = props.params;
  let page = props.page;
  let limit = props.limit;
  let filter = "";
  if (Object.keys(props.filter).length !== 0) {
    Object.keys(props.filter).forEach(function(key) {
      filter = filter + props.filter[key];
    });
  }
  let response = "";
  try {
    response = await apiGetWithToken(
      PATH_LOAN.LOAN_lIST + "?page=" + page + "&limit=" + limit + filter,
      params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getLoanHistory(props) {
  let params = props.params;
  let id = props.id;
  let page = props.page;
  let limit = props.limit;
  let filter = "";
  if (Object.keys(props.filter).length !== 0) {
    Object.keys(props.filter).forEach(function(key) {
      filter = filter + props.filter[key];
    });
  }
  let response = "";
  try {
    response = await apiGetWithToken(
      PATH_LOAN.LOAN_lIST + "?employee_id=" + id + "&page=" + page + "&limit=" + limit + filter, 
      params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getLoanReport(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_LOAN.LOAN_DOWNLOAD, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

const loan = {
  getLoanList : getLoanList,
  getLoanHistory : getLoanHistory,
  getLoanReport : getLoanReport
};

export default loan;
