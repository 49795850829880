import React from 'react';
import EmployeeNewForm from '../../containers/EmployeeNewForm';

function EmployeeNew(props) {
    return (
        <React.Fragment>
            <EmployeeNewForm />
        </React.Fragment>
    );
}

export default EmployeeNew;