import React from 'react';
import InvoiceListContent from '../../containers/InvoiceList';

function InvoiceList(props) {
    return (
        <React.Fragment>
            <InvoiceListContent  />
        </React.Fragment>
    );
}

export default InvoiceList;