import styled from "styled-components";

export const Section = styled.div`

 .kw-mainLayout-wrapper {
    background-color: #e5e5e5;
    height: 100vh;
    .main-header {
      position: fixed;
      background: white;
      max-height: 80px;
      width: 100%;
      z-index: 1;
      padding: 0!important;

      &__logo {
        background: #443f5f;
        width: 90px;
        height: 80px;
        padding: 10px;
        img {
          width: 64px;
        }
      }
      .toggle-menu {
        position: fixed;
        left: 120px;
        span {
          font-size: 21px;
        }
      }
      .toggle-profile-menu {
        margin-right: 20px;
        margin-bottom: 10px;
        span {
          margin:0 5px;
          cursor: pointer;
        }
      }
    }
    .main-sidebar {
      background: #443F5F;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      width: 90px;
      transition: 0.5s;
      &__menu {
        padding-top: 20px;
        position: relative;
        top: 110px;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          text-align: center;
          padding: 15% 0;
          .anticon {
            font-size: 30px;
            color: #A3A0FB;
            width: 100%;
          }
          .menu-label {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            line-height: 2;
            display: inherit;
          }
        }
        .active {
          background: #ffffff;
          span {
            color: #443F5F;
          }
        }
      }
    }
    .hide-menu {
      margin-left: -90px;
      ul {
        display: none;
        transition: 0.5s;
      }
    }
    .content-wrapper {
      transition: 0.5s;
      margin-left: 90px;
      padding: 15px;
      top: 80px;
      position: relative;
      background: #e5e5e5;
    }
    .full-width {
      margin-left: 0;
      width: 100%;
    }
 }

`