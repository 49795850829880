import { apiPostWithoutToken } from "../../services/api";
import { PATH_AUTHENTICATION } from "../../services/path/authentication";

async function checkAuthentication(props) {
  const actionChangeLoadingShow = props.actionChangeLoadingShow
    ? props.actionChangeLoadingShow
    : function() {};
  const actionChangeAlert = props.actionChangeAlert ? props.actionChangeAlert : function() {};
  let params = props.params;
  let response = "";
  actionChangeLoadingShow(true);
  try {
    response = await apiPostWithoutToken(
      PATH_AUTHENTICATION.AUTHENTICATION,
      params
    );
    actionChangeAlert(false);
    actionChangeLoadingShow(false);
  } catch (error) {
    actionChangeAlert(true);
    actionChangeLoadingShow(false);
    response = error.response;
  }   
  return response && response.data;
}



const authentication = {
  checkAuthentication: checkAuthentication
};

export default authentication;
