import styled from "styled-components";

export const Section = styled.div`
    .kwEmployee {
        padding: 0 1%;
        &__top-button {
            text-align: right;
            padding-top: 10px;
            margin: 0;
            top: -5px;
            button {
                margin: 0 5px;
            }
        }
        &__top-alert {
            margin: 15px 0;
            padding-right: 5px;
        }
        &__right-content {
            margin: 30px 0 0;
            padding-top: 20px;
        }
        h2 {
            font-style: normal;
            font-weight: bold;
            font-size: 42px;
            color: #2d2d2d;
            .anticon-left {
                position: relative;
                top: -10px;
            }
        }
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 27px;
            color: #000000;
            margin-bottom: 30px;
        }
        .ant-form {
            padding: 10px 10px 10px 0;
        }
        .ant-table {
            .ant-input {
                border-radius: 10px;
            }
            .ant-select-selector {
                border-radius: 10px;
            }
        }
        .input-label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-transform: uppercase;
            color: #000000;
        }
        .p-right-aligned {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            text-align: left;
            color: #071e35;
            margin-top: 10px;
            padding-left: 20%;
            display: block;
        }
        .text-status {
            border-radius: 100px;
            display: block;
            width: 200px;
            text-align: center;
            color: #fff;
            font-weight: bold;
            margin-left: 20%;
        }
        .status-activated {
            background: #41af60;
        }
        .status-resigned {
            background: #c53741;
        }
        .status-pending {
            background: #fdca44;
        }
        .status-unreachable {
            background: #fdca44;
        }
        .status-frozen {
            background: #c53741;
        }
        .status-rejected {
            background: #c53741;
        }
        .btn-edit-employee {
            position: absolute;
            right: 0;
        }
        .ml-20 {
            margin-left: 20%;
        }
    }
    .ant-modal-header {
        background: #c53741;
    }
`;
