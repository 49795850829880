import React from 'react';
import PartnershipForm from '../../containers/Partnership';

function Partnership(props) {
    return (
        <React.Fragment>
            <PartnershipForm/>
        </React.Fragment>
    );
}

export default Partnership;