import React from 'react';
import LoanListContent from '../../containers/LoanList';

function LoanList(props) {
    return (
        <React.Fragment>
            <LoanListContent />
        </React.Fragment>
    );
}

export default LoanList;