import React, { useState, useEffect } from "react";
import { Table, Card, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import history from "../../routers/history";
import discrepancy from "../../repository/Discrepancy";
import MenuList from "../../components/Menu/MenuList";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import PATH_URL from "../../routers/path";

function DiscrepancyDetailContent(props) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));
  const period = props.match.params.month.match(/[a-zA-Z]+|[0-9]+/g);
  const month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(period[0]) / 3 + 1;
  const year = period[1];

  const TableHead = [
    { 
      title: t("employee:label.no"), 
      dataIndex: "number", 
      key: "number" 
    },
    { 
      title: t("employee:label.employeeName"), 
      dataIndex: "employeeName", 
      key: "employeeName" 
    },
    { 
      title: t("employee:label.salaryGiven"), 
      dataIndex: "salaryGiven", 
      key: "salaryGiven" 
    },
    { 
      title: t("employee:label.bankAccountNumber"), 
      dataIndex: "bankAccountNumber", 
      key: "bankAccountNumber" 
    },
    { 
      title: t("employee:label.salaryDisbursed"), 
      dataIndex: "salaryDisbursed", 
      key: "salaryDisbursed" 
    },
    { 
      title: t("employee:label.discrepancy"), 
      dataIndex: "discrepancy", 
      key: "discrepancy" 
    }
  ];

  useEffect(() => {
    let unmounted = false;
    discrepancy
      .getDetail({
        id: dataVendor.vid,
        month: month,
        year: year
      })
      .then(res => {
        if (!unmounted && res.status === 200 && res.data) {
          const items = res.data.map(
            item => ({
              key: item.discrepancy_id,
              number: res.data.indexOf(item)+1,
              salaryDisbursed: currencyNoPrefix(item.disbursed_loan),
              salaryGiven: currencyNoPrefix(item.salary_given),
              discrepancy: currencyNoPrefix(item.discrepancy_amount),
              employeeName: item.employee_name,
              bankAccountNumber: item.bank_account_number
            })            
          );
          setDataSource(items);
        }
      });

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <Button 
        type="primary" 
        onClick={() => history.push(PATH_URL.DISCREPANCY)}
        danger
      >
        &larr; {t("employee:button.back")}
      </Button>
      <div className="kwDiscrepancy">
        <h2>{t("employee:title.discrepancy")} - {period[0]} {period[1]}</h2>
        <MenuList
          activeKey="discrepancy"
        />
        <Card>
          <h3>{dataVendor.name}</h3>
          {/* <div className="kwDiscrepancy__top-button">
          <Button 
              type="default"
            >
              {t("employee:button.edit")}
            </Button>
            <Button 
              type="primary"
            >
              {t("employee:button.sent")}
            </Button>
          </div>   */}
          <Table
            columns={TableHead}
            dataSource={dataSource}
            pagination={false}
          />   
            
        </Card>
      </div>    
    </Section>
  );
};

export default DiscrepancyDetailContent;
