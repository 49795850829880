import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { persistor, store } from "./stores";
import { PersistGate } from "redux-persist/integration/react";
// import ClevertapReact from "./library/clevertap";
import clevertap from 'clevertap-web-sdk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import GlobalStyle from "./assets/js/globalstyles";
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_ID,
  site: process.env.REACT_APP_DATADOG_SITE,
  service:process.env.REACT_APP_DATADOG_SERVICE,
  
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'mask-user-input',
    
  // discard a RUM error if its message includes 'ResizeObserver loop'
  // to solve this issue: https://koinworks.atlassian.net/browse/MFI-5, https://koinworks.atlassian.net/browse/MFI-362
  beforeSend: (event, context) => {
    if (event.type === 'error' && event.error.message.includes('ResizeObserver loop')) {
        return false
    }
  },
});
    
datadogRum.startSessionReplayRecording();

clevertap.init(process.env.REACT_APP_CLEVERTAP_ID, 'aps3');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle/>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
