import { apiPostUploadWithForm, apiPostWithToken } from "../../services/api";
// import { apiPostWithTokenUpload } from "../../services/api";
import { PATH_UPLOAD } from "../../services/path/upload";


async function uploadFile(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiPostUploadWithForm(PATH_UPLOAD.UPLOAD_FILE, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function uploadFileNew(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiPostUploadWithForm(PATH_UPLOAD.UPLOAD_FILE_NEW, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function validateData(props) {
  let params = props.params;
  let response = "";

  try {
    response = await apiPostWithToken(PATH_UPLOAD.VALIDATE_DATA, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

const upload = {
  uploadFile : uploadFile,
  uploadFileNew : uploadFileNew,
  validateData : validateData
};

export default upload;
