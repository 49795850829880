import styled from "styled-components";

export const Section = styled.div`
	.kwHelp {
    padding: 0 1%;
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    }
    .ant-card {
      margin: 0 1%;
    }
    .content-block {
      margin: 20px 0;
      padding: 20px 0;
    }
    .ant-btn {
      margin-right: 10px;
    }
  }
`;