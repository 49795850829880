import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import common_id from "../assets/languages/common/id.json";
import common_en from "../assets/languages/common/en.json";
import dashboard_id from "../assets/languages/dashboard/id.json";
import dashboard_en from "../assets/languages/dashboard/en.json";
import setting_id from "../assets/languages/setting/id.json";
import setting_en from "../assets/languages/setting/en.json";
import employee_id from "../assets/languages/employee/id.json";
import employee_en from "../assets/languages/employee/en.json";
import help_id from "../assets/languages/help/id.json";
import help_en from "../assets/languages/help/en.json";
import fee_id from "../assets/languages/fee/id.json";
import fee_en from "../assets/languages/fee/en.json";
import promo_id from "../assets/languages/promo/id.json";
import promo_en from "../assets/languages/promo/en.json";

i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false
        },
        resources: {            
            id: {
                common: common_id,
                dashboard: dashboard_id,
                setting: setting_id,
                employee: employee_id,
                help: help_id,
                fee: fee_id,
                promo: promo_id
            },
            en : {
                common: common_en,
                dashboard: dashboard_en,
                setting: setting_en,
                employee: employee_en,
                help: help_en,
                fee: fee_en,
                promo: promo_en
            }
        }
    })

export default i18n;