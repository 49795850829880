import styled from "styled-components";

export const Section = styled.div`
	.kwDiscrepancy {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 10px;
      top: -25px;
      position: relative;
      button {
        margin: 0 5px;
      }
    }
    &__top-description {
      float: right;
      position: absolute;
      top: 10px;
      right: 0;
      margin: 10px 15px;
      padding-right: 15px;
      font-size: 24px;
      font-weight: 500;
      text-align: right;
      color: #2D2D2D;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #566068;
      margin-bottom: 30px;
    }
    .btn-table {
      .ant-btn {
        margin: 4px;
        span {
          font-weight: 400;
          font-size: 12px;
        }
      }
      .default {
        color: #566068;
        border: 1px solid #566068;
      }
      .success {
        color: #41AF60;
        border: 1px solid #41AF60;
      }
      .danger {
        color: #9E2C34;
        border: 1px solid #9E2C34;
      }
      .disable {
        background: #efefef;
        cursor: no-drop;
      }
    }
  }
`;