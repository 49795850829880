import { apiGetWithToken } from "../../services/api";
import { PATH_INVOICE } from "../../services/path/invoice";


async function getInvoiceList(props) {
  let params = props.params;
  let page = props.page;
  let limit = props.limit;
  let response = "";
  let filter = "";
  if (Object.keys(props.filter).length !== 0) {
    Object.keys(props.filter).forEach(function(key) {
      filter = filter + props.filter[key];
    });
  }
  try {
    response = await apiGetWithToken(
      PATH_INVOICE.INVOICE_LIST + "?page=" + page + "&limit=" + limit + filter,
      params
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getInvoiceDetail(props) {
  let params = props.params;
  let id = props.id;
  let page = props.page;
  let limit = props.limit;
  let response = "";
  let filter = "";
  if (Object.keys(props.filter).length !== 0) {
    Object.keys(props.filter).forEach(function(key) {
      filter = filter + props.filter[key];
    });
  }
  try {
    response = await apiGetWithToken(
      PATH_INVOICE.INVOICE_DETAIL + id + "?page=" + page + "&limit=" + limit + filter,
      params
    );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function downloadInvoice(props) {
  let params = props.params;
  let code = props.code;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_INVOICE.INVOICE_DOWNLOAD + code, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

const invoice = {
  getInvoiceList : getInvoiceList,
  getInvoiceDetail : getInvoiceDetail,
  downloadInvoice : downloadInvoice
};

export default invoice;
