import React from 'react';
import DiscrepancyUploadContent from '../../containers/DiscrepancyUpload';

function DiscrepancyUpload(props) {
    return (
        <React.Fragment>
            <DiscrepancyUploadContent/>
        </React.Fragment>
    );
}

export default DiscrepancyUpload;