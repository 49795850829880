import React, { useState } from "react";
import { 
  Card, 
  Row, 
  Col, 
  Button, 
  Form, 
  Input, 
  InputNumber,
  DatePicker,
  message,
  Select
} from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import history from "../../routers/history";
import employee from "../../repository/Employee";
import { Section } from "./style";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import PATH_URL from "../../routers/path";
import { stausEmployeeOption } from "./dataOption";
import moment from "moment";

function EmployeeNewForm(props) {
  const { t } = useTranslation(); 
  const [name, setName] = useState("");
  const [number,setNumber] = useState("");
  const [dob, setDob] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nik, setNik] = useState("");
  const [firstDow, setFirstDow] = useState("");
  const [salary, setSalary] = useState(0);
  const [department, setDepartment] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [position, setPosition] = useState("");
  const [endOfContract, setEndOfContract] = useState("");

  const [loading, setLoading] = useState(false);
  const [eocDisable, setEocDisable] = useState(true);

  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));
  // const lang = window.localStorage.getItem("language") || "en";

  const [mandatoryName, setMandatoryName] = useState("");
  const [mandatoryDob, setMandatoryDob] = useState("");
  const [mandatorySalary, setMandatorySalary] = useState("");
  const [mandatoryDow, setMandatoryDow] = useState("");
  const [mandatoryBankName, setMandatoryBankName] = useState("");
  const [mandatoryBankAccount, setMandatoryBankAccount] = useState("");
  const [mandatoryNik, setMandatoryNik] = useState("");
  const [mandatoryEmploymentType, setMandatoryEmploymentType] = useState("");
  const [mandatoryPosition, setMandatoryPosition] = useState("");
  const [mandatoryEndOfContract, setMandatoryEndOfContract] = useState("");

  function handleNameChange({ target }) { 
    setName(target.value);
    validateName(target.value);
  }
  function handleNumberChange({ target }) { 
    setNumber(target.value);
  }
  function handleNikChange({ target }) { 
    setNik(target.value); 
    validateNik(target.value);
  }
  function handlePhoneChange({ target }) { 
    setPhoneNumber(target.value); 
  }
  function handleDepartmentChange({ target }) { 
    setDepartment(target.value); 
  }
  function handleBankNameChange({ target }) { 
    setBankName(target.value); 
    validateBankName(target.value);
  }
  function handleAccountNumber({ target }) { 
    setAccountNumber(target.value); 
    validateBankAccount(target.value);
  }
  function handleDobChange(date, dateString) {
    setDob(dateString);
    validateDob(date);
  }
  function handleDowChange(date, dateString) {
    setFirstDow(dateString);
    validateDow(date);
  }
  function handleSalaryChange(value) {
    setSalary(value);
    validateSalary(value);
  }
  function handleStatusEmployeeChange(value){    
    setEocDisable(false);
    if (value === 'permanent'){
      setEocDisable(true);
    }

    setEmploymentType(value);
    validateEmploymentType(value);
  }
  function handlePositionChange({ target }){
    setPosition(target.value);
    validatePosition(target.value);
  }
  function handleEndOfContractChange(date, dateString) {
    setEndOfContract(dateString);
    validateEndOfContract(date);
  }

  function actionChangeLoading(value) {
    setLoading(value);
  }

  function validateName(value) {
    let status = value === "" ? "error" : "";
    setMandatoryName(status);
  }

  function validateDob(value) {
    let status = value === "" ? "error" : "";
    setMandatoryDob(status);
  }

  function validateDow(value) {
    let status = value === "" ? "error" : "";
    setMandatoryDow(status);
  }

  function validateSalary(value) {
    let status = value === "" || value === 0 ? "error" : "";
    setMandatorySalary(status);
  }

  function validateBankName(value) {
    let status = value === "" ? "error" : "";
    setMandatoryBankName(status);
  }

  function validateBankAccount(value) {
    let status = value === "" ? "error" : "";
    setMandatoryBankAccount(status);
  }

  function validateNik(value) {
    let status = value === "" ? "error" : "";
    setMandatoryNik(status);
  } 

  function validateEmploymentType(value){
    let status = value === "" ? "error" : "";
    setMandatoryEmploymentType(status);
  }

  function validatePosition(value){
    let status = value === "" ? "error" : "";
    setMandatoryPosition(status);
  }

  function validateEndOfContract(value){
      let status = value === "" ? "error" : "";
      setMandatoryEndOfContract(status);
  }  

  function handleSubmit() {
    validateName(name);
    validateDob(dob);
    validateDow(firstDow);
    validateSalary(salary);
    validateBankName(bankName);
    validateBankAccount(accountNumber);
    validateNik(nik);
    validateEmploymentType(employmentType);
    validatePosition(position);
    validateEndOfContract(endOfContract);

    if (!name || !dob || !firstDow || !nik
        || !salary || !bankName || !accountNumber
        || !employmentType || !position
    ) {
      return ;
    } else {
      let params = {
        vendor_id: dataVendor.vid,
        account_number: accountNumber,
        salary: salary,
        employee_number: number,
        employee_name: name,
        department: department,
        bank_name: bankName,
        nik: nik,
        dob: dob,
        phone_number: phoneNumber,
        employeed_since: firstDow,
        employment_type: employmentType,
        position: position,
        end_of_contract: (eocDisable) ? '' : endOfContract,
        status: 'pending'
      };
          
      actionChangeLoading(true);
      employee
        .postNewEmployee({
          params: params
        })
        .then(resp => {
          if (resp.status === 200 ) {
            history.push(PATH_URL.EMPLOYEE_LIST);
          } else {
            let errMsg = `${resp.status}: Failed to send data. `;
            if (resp.errors?.length > 0 && ('moreInfo' in resp.errors[0])) {
              errMsg += resp.errors[0].moreInfo;
            } else {
              errMsg += resp.message.en;
            }
            message.error(errMsg);
            actionChangeLoading(false);
          }
        });
    }
  }

  return (
    <Section>
      <div className="kwSetting">
        <h2>
          <span
            onClick={() => history.push(PATH_URL.EMPLOYEE_LIST)}
          >
            <LeftOutlined /> {" "}
          </span>
          {t("employee:title.backToEmployee")}
        </h2>
        <Card>
          <h3>{t("employee:title.addNewEmployee")}</h3>
          <div className="kwSetting__top-button">
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            >
              {t("employee:button.submit")}
            </Button>
          </div>
          <Form>
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  validateStatus={mandatoryName}
                >
                  <span className="input-label">
                    {t("employee:label.employeeName")}
                  </span>
                  <Input
                    type="text"
                    id="employeeName"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {mandatoryName && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("employee:label.employeeNumber")}
                  </span>
                  <Input
                    type="text"
                    id="employeeNumber"
                    value={number}
                    onChange={handleNumberChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryDob}
                >
                  <span className="input-label">
                    {t("employee:label.birthDate")}
                  </span>
                  <DatePicker
                    className="w-100"
                    id="birthDate"
                    allowClear={false}
                    onChange={handleDobChange}
                  />
                  {mandatoryDob && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("employee:label.phoneNumber")}
                  </span>
                  <Input
                    type="tel"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryNik}
                >
                  <span className="input-label">
                    {t("employee:label.ktpNumber")}
                  </span>
                  <Input
                    type="text"
                    id="nik"
                    value={nik}
                    onChange={handleNikChange}
                  />
                  {mandatoryNik && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryEmploymentType}
                >
                  <span className="input-label">
                    {t("employee:label.status")}
                  </span>
                    <Select
                      defaultValue={employmentType}
                      onChange={handleStatusEmployeeChange}
                      id="employmentType"
                    >
                      {stausEmployeeOption.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>                        
                  {mandatoryEmploymentType && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}                            
                </Form.Item>
                <Form.Item 
                  validateStatus={mandatoryPosition}
                >
                  <span className="input-label">
                    {t("employee:label.position")}
                  </span>
                  <Input
                    type="text"
                    id="position"
                    value={position}
                    onChange={handlePositionChange}
                  />
                  {mandatoryPosition && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>                                
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  validateStatus={mandatoryDow}
                >
                  <span className="input-label">
                    {t("employee:label.firstWork")}
                  </span>
                  <DatePicker
                    className="w-100"
                    id="firstWork"
                    allowClear={false}
                    onChange={handleDowChange}
                  />
                  {mandatoryDow && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={mandatorySalary}
                >
                  <span className="input-label">
                    {t("employee:label.currentSalary")}
                  </span>
                  <InputNumber
                    type="text"
                    className="w-100"
                    id="salary"
                    value={salary}
                    formatter={value => `${currencyNoPrefix(value)}`}
                    onChange={handleSalaryChange}
                  />
                  {mandatorySalary && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("employee:label.department")}
                  </span>
                  <Input
                    type="text"
                    id="department"
                    value={department}
                    onChange={handleDepartmentChange}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryBankName}
                >
                  <span className="input-label">
                    {t("employee:label.bankName")}
                  </span>
                  <Input
                    type="text"
                    id="bankName"
                    value={bankName}
                    onChange={handleBankNameChange}
                  />
                  {mandatoryBankName && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryBankAccount}
                >
                  <span className="input-label">
                    {t("employee:label.bankAccountNumber")}
                  </span>
                  <Input
                    type="text"
                    id="accountNumber"
                    value={accountNumber}
                    onChange={handleAccountNumber}
                  />
                  {mandatoryBankAccount && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={(!eocDisable) ? mandatoryEndOfContract : ''}
                >
                  <span className="input-label">
                    {t("employee:label.endOfContract")}
                  </span>
                  <DatePicker
                    className="w-100"
                    id="endOfContractt"
                    allowClear={true}
                    onChange={handleEndOfContractChange}
                    disabled={eocDisable}
                    disabledDate={d => !d || d.isBefore(moment().add(1,'months'))}
                  />
                  { (!eocDisable) && mandatoryEndOfContract && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>                
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Section>
  );
};

export default EmployeeNewForm;
