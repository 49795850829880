import styled from "styled-components";

export const Section = styled.div`
	.kwEmployee {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 20px 10px 10px 10px;
      position: relative;
      margin: 15px 0;
      button {
        margin: 0 5px;
      }
    }
    &__top-description {
      padding-right: 15px;
      font-size: 17px;
      font-weight: 500;
      text-align: right;
      color: #2D2D2D;
      margin-left: auto;
      margin-right: 0;
      table {
        td {
          border: none;
          padding: 0 5px;
        }
      }
      .paydate-text {
        text-align: left;
      }
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #566068;
      position: relative;
      top: 25px;
    }
    .ant-tabs {
      background: #ffffff;
      padding: 0 10px;
    }
    .ant-input {
      border-radius: 10px;
    }
    .btn-table {
      .ant-btn {
        margin: 4px;
        span {
          font-weight: 400;
          font-size: 12px;
        }
      }
      .default {
        color: #566068;
        border: 1px solid #566068;
      }
      .success {
        color: #41AF60;
        border: 1px solid #41AF60;
      }
      .danger {
        color: #9E2C34;
        border: 1px solid #9E2C34;
      }
    }
    .row-delete {
      background: #cc111126;
    }
    .pagination {
      float: right;
      margin: 15px 0;
      padding: 10px;
    }
    .pointer {
      cursor: pointer;
    }
  }
`;