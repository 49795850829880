import { apiGetWithToken, apiPutWithToken, apiPostWithToken, apiDeleteWithToken } from '../../services/api';
import { PATH_EMPLOYEE } from '../../services/path/employee';

async function getEmployeeList(props) {
    let params = props.params;
    let page = props.page;
    let limit = props.limit;
    let filter = '';
    if (Object.keys(props.filter).length !== 0) {
        Object.keys(props.filter).forEach(function (key) {
            filter = filter + props.filter[key];
        });
    }
    let response = '';
    try {
        response = await apiGetWithToken(
            PATH_EMPLOYEE.EMPLOYEE_LIST + '?page=' + page + '&limit=' + limit + filter,
            params
        );
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function getEmployeeDetail(props) {
    let params = props.params;
    let id = props.id;
    let response = '';
    try {
        response = await apiGetWithToken(PATH_EMPLOYEE.EMPLOYEE_DETAIL + id, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function putEmployeeDetail(props) {
    let params = props.params;
    let id = props.id;
    let response = '';
    try {
        response = await apiPutWithToken(PATH_EMPLOYEE.EMPLOYEE_UPDATE + id, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function deleteEmployee(props) {
    let params = props.params;
    let id = props.id;
    let response = '';
    try {
        response = await apiDeleteWithToken(PATH_EMPLOYEE.EMPLOYEE_DELETE + id, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function postNewEmployee(props) {
    let params = props.params;
    let response = '';
    try {
        response = await apiPostWithToken(PATH_EMPLOYEE.EMPLOYEE_CREATE, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function postBulkUpload(props) {
    let params = props.params;
    let response = '';
    try {
        response = await apiPostWithToken(PATH_EMPLOYEE.EMPLOYEE_BULK_UPLOAD, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function putFreezeEmployee(props) {
    let id = props.id;
    let status = props.status;
    let response = '';
    try {
        response = await apiPutWithToken(PATH_EMPLOYEE.EMPLOYEE_FREEZE + id + '?status=' + status);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function postEmployeeStatus(props) {
    let params = props.params;
    let id = props.id;
    let response = '';
    try {
        response = await apiPostWithToken(PATH_EMPLOYEE.EMPLOYEE_STATUS_UPDATE + id + '/update', params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function postResignedEmployees(props) {
    let params = props.params;
    let response = '';

    try {
        response = await apiPostWithToken(PATH_EMPLOYEE.EMPLOYEE_RESIGNED, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function getEmployeeOutstanding(props) {
    let employee_id = props.employee_id;
    let response = '';
    try {
        response = await apiGetWithToken(PATH_EMPLOYEE.EMPLOYEE_OUTSTANDING + employee_id);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function getEmployeeTemplate(props) {
    let params = props.params;
    let response = '';
    try {
        response = await apiGetWithToken(PATH_EMPLOYEE.EMPLOYEE_TEMPLATE, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function getDownloadEmployeeList(props) {
    let vendor_id = props.id;
    let response = '';
    try {
        response = await apiGetWithToken(PATH_EMPLOYEE.EMPLOYEE_LIST + '/' + vendor_id + '/download');
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function putFreezeAllEmployee(props) {
    let vendor_id = props.vendor_id;
    let params = props.params;
    let response = '';
    try {
        response = await apiPutWithToken(PATH_EMPLOYEE.EMPLOYEE_FREEZE_ALL + vendor_id + '/freeze-all', params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function putLoanOption(props) {
    let vendor_id = props.vendor_id;
    let params = props.params;
    let response = '';
    try {
        response = await apiPutWithToken(
            PATH_EMPLOYEE.EMPLOYEE_LOAN_OPTION_ALL + vendor_id + '/loan-access/update',
            params
            );
        } catch (error) {
            response = error.response;
        }
        return response && response.data;
    }
    
async function putFreezeOptionEmployee(props) {
    let vendor_id = props.vendor_id;
    let params = props.params;
    let response = '';
    try {
        response = await apiPutWithToken(PATH_EMPLOYEE.EMPLOYEE_FREEZE_ALL + vendor_id + '/freeze-option', params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

async function postBulkUploadV2(props) {
    let params = props.params;
    let response = '';
    try {
        response = await apiPostWithToken(PATH_EMPLOYEE.EMPLOYEE_BULK_UPLOAD_V2, params);
    } catch (error) {
        response = error.response;
    }
    return response && response.data;
}

const employee = {
    getEmployeeList: getEmployeeList,
    getEmployeeDetail: getEmployeeDetail,
    putEmployeeDetail: putEmployeeDetail,
    postBulkUpload: postBulkUpload,
    postNewEmployee: postNewEmployee,
    deleteEmployee: deleteEmployee,
    putFreezeEmployee: putFreezeEmployee,
    postEmployeeStatus: postEmployeeStatus,
    postResignedEmployees: postResignedEmployees,
    getEmployeeOutstanding: getEmployeeOutstanding,
    getEmployeeTemplate: getEmployeeTemplate,
    getDownloadEmployeeList: getDownloadEmployeeList,
    putFreezeAllEmployee: putFreezeAllEmployee,
    putLoanOption: putLoanOption,
    putFreezeOptionEmployee: putFreezeOptionEmployee,
    postBulkUploadV2: postBulkUploadV2
};

export default employee;
