import React, { useState } from "react";
import { Card, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import history from "../../routers/history";
import MenuList from "../../components/Menu/MenuList";
import DiscrepancyManualModal from "../../components/Modal/DiscrepancyManualModal";
import DiscrepancyBulkModal from "../../components/Modal/DiscrepancyBulkModal";
import discrepancyCsv from "../../assets/files/template_add_discrepancy.xlsx";
import PATH_URL from "../../routers/path";

function DiscrepancyUploadContent(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));

  return (
    <Section>
      <Button 
        type="primary" 
        onClick={() => history.push(PATH_URL.DISCREPANCY)}
        danger
      >
        &larr; {t("employee:button.back")}
      </Button>

      <div className="kwDiscrepancy">
        <h2>{t("employee:title.discrepancy")}</h2>
        <MenuList
          activeKey="discrepancy"
        />
        <Card>
          <h3>{dataVendor.name}</h3>
          <div className="kwDiscrepancy__top-button">
            <Button
              type="default"
              onClick={() => setShow1(true)}
            >
              {t("employee:button.uploadDiscrepancyXls")}
            </Button>
            <Button
              type="default"
              onClick={() => setShow(true)}
            >
              {t("employee:button.uploadDiscrepancyManual")}
            </Button>
          </div>  
          <div className="kwDiscrepancy__text-content">
            <p>
              {t("employee:text.pleaseUploadDiscrepancies")}
            </p>
            <p>
              <strong>{t("employee:text.ifNoDiscrepancy")}</strong>
            </p>
            <p>
              {t("employee:text.ifThereAreDiscrepancy")}
            </p>
          </div>
          <div className="kwDiscrepancy__bottom-button">
            <Button
              type="default"
              onClick={() => history.push(PATH_URL.DISCREPANCY)}
            >
              {t("employee:button.showMonthlyDiscrepancy")}
            </Button>
            <a
              href={discrepancyCsv}
              rel="noopener noreferrer"
              download
            >
              <Button type="default" >
                {t("employee:button.downloadTemplate")}
              </Button>
            </a>
          </div>    
        </Card>
      </div>    

      {/* --- manual upload --- */}
      <DiscrepancyManualModal 
        visible={show}
        width={1000}
        lang={t}
        onClose={() => {setShow(false)}}
      />  

      {/* --- bulk upload --- */}
      <DiscrepancyBulkModal 
        visible={show1}
        width={500}
        lang={t}
        onClose={() => {setShow1(false)}}
      />

    </Section>
  );
};

export default DiscrepancyUploadContent;
