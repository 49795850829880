import axios from "axios";
import cookie from "react-cookies";

const REACT_APP_API_SERVICE = process.env.REACT_APP_API_MAIN_SERVICE;
const REACT_APP_API_COMMON_SERVICE = process.env.REACT_APP_API_COMMON_SERVICE;
// const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';

const getToken = () => {
  return cookie.load("token");
};

const dummyTokenUpload =
  "eyJhY2NvdW50X3R5cGUiOiIzYjZiMjIwYi0yMDE5LTQyNzktODM0My1jYmU2MTY3Zjc2MDIiLCJlbWFpbCI6ImR3aXN0YXJpMTZAZ21haWwuY29tIiwiZXhwIjoxNjE0MTQ2NDQ3";

export const serviceWithToken = (token = getToken()) => {
  if (token === null) {
    token = getToken();
  }
  return axios.create({
    baseURL: `${REACT_APP_API_SERVICE}`,
    timeout: 60 * 4 * 1000,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": `application/json`
    }
  });
};

export const serviceUploadWithToken = () =>
  axios.create({
    baseURL: REACT_APP_API_COMMON_SERVICE,
    timeout: 60 * 4 * 1000,
    headers: {
      token: dummyTokenUpload,
      "Content-Type": `application/json`
    }
  });

  export const serviceUploadFormWithToken = () =>
  axios.create({
    baseURL: REACT_APP_API_COMMON_SERVICE,
    timeout: 60 * 4 * 1000,
    headers: {
      token: dummyTokenUpload,
      "Content-Type": `multipart/form-data`
    }
  });

export const serviceWithoutToken = () =>
  axios.create({
    baseURL: `${REACT_APP_API_SERVICE}`,
    timeout: 60 * 4 * 1000,
    headers: {
      "Content-Type": `application/json`
    }
  });
