import React from 'react';
import DiscrepancyList from '../../containers/Discrepancy';

function Discrepancy(props) {
    return (
        <React.Fragment>
            <DiscrepancyList/>
        </React.Fragment>
    );
}

export default Discrepancy;