export function getStatementDate(date) {
  let statementDate = "";
  if (date !== "") {
    let d = new Date();
    let givenDate = new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + date);
    let statDate = givenDate.setDate(new Date(givenDate).getDate() - 3);
    statementDate = new Date(statDate).getDate();
  }
  return statementDate;
}

export function getMaxApplyDate(date) {
  let maxApplyDate = "";
  if (date !== "") {
    let d = new Date();
    let givenDate = new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + date);
    let statDate = givenDate.setDate(new Date(givenDate).getDate() - 4);
    maxApplyDate = new Date(statDate).getDate();
  }
  return maxApplyDate; 
}

export function isPreventDownloadDate(dataPartner, data) {
  let startPayroll = parseInt(dataPartner.payroll_start_date);
  let dueDate = parseInt(dataPartner.payroll_end_date);
  let today = parseInt(new Date().getDate());

  if(data.status === 'paid') {
    return false;
  } else {
    if(today >= (startPayroll - 3) && today <= dueDate) {
      return false;
    } else {
      return true;
    }
  }
}

export function isPreventDownloadDate2(dataPartner, date, status) {
  // const monthList = [
  //   "Jan", "Feb", "Mar", "Apr", "Mei", "Jun",
  //   "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
  // ];
  
  if(isNaN(Date.parse(date))) {
    return true;
  }
  let invDate = date.split("-");

  let startPayroll = parseInt(dataPartner.start);
  let dueDate = parseInt(dataPartner.end);
  let today = parseInt(new Date().getDate());
  let toMonth = parseInt(new Date().getMonth());

  if (status === 'paid') {
    return false;
  } else {
    if (toMonth > parseInt(invDate[1]) - 1)
      return false;
    else if(today >= (startPayroll - 3) && today <= dueDate) 
      return false;
    else
      return true;    
  }
}