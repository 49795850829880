import React from 'react';
import SuccessFeeContent from '../../containers/SuccessFee';

function SuccessFee(props) {
    return (
        <React.Fragment>
            <SuccessFeeContent />
        </React.Fragment>
    );
}

export default SuccessFee;