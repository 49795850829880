import React, { useState, useEffect } from "react";
import {
    Tag,
    Card,
    Input,
    Select,
    Button,
    Table,
    Pagination,
    message,
} from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import loan from "../../repository/Loan";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import { dateLocaleStringFormat } from "../../library/dateTimeFormatter";
import MenuList from "../../components/Menu/MenuList";
import { snakeCaseToCapital } from "../../library/stringFormatter";

function LoanListContent(props) {
    const { t } = useTranslation();
    const [dataLoan, setDataLoan] = useState([]);
    const [totalLoan, setTotalLoan] = useState("");
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(0);
    const [filters, setFilters] = useState({});
    const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));
    const limit = 10;
    const { Option } = Select;

    /** handle data filtering by input text */
    function handleFilter(dataIndex, { target }) {
        let term = target.value;
        let terms = filters;
        if (term.length > 1) {
            terms[target.name] = "&" + target.name + "=" + term;
            setFilters(terms);
            setPage(1);
            setSearch(search + 1);
        }
        if (term.length === 0) {
            let key = target.name;
            delete terms[key];
            setFilters(terms);
            if (search > 0) {
                setSearch(search - 1);
            }
        }
    }

    /** handle data filtering by dropdown status */
    function handleFilterStatus(value) {
        let name = "status";
        let terms = filters;
        if (value !== "") {
            terms[name] = "&" + name + "=" + value;
            setFilters(terms);
            setPage(1);
            setSearch(search + 1);
        } else {
            let key = name;
            delete terms[key];
            setFilters(terms);
            if (search > 0) {
                setSearch(search - 1);
            }
        }
    }

    /** handle data filtering by dropdown load type */
    function handleFilterLoanType(value) {
        let name = "loan_type";
        let terms = filters;
        if (value !== "") {
            terms[name] = "&" + name + "=" + value;
            setFilters(terms);
            setPage(1);
            setSearch(search + 1);
        } else {
            let key = name;
            delete terms[key];
            setFilters(terms);
            if (search > 0) {
                setSearch(search - 1);
            }
        }
    }

    function handleDownload() {
        let lang = window.localStorage.getItem("language") || "en";
        loan.getLoanReport({}).then((res) => {
            if (res.status === 200 && res.data) {
                window.location.href = res.data.url_download;
            } else {
                message.error(res.message[lang]);
            }
        });
    }

    function handleChangePage(value) {
        setPage(value);
        loan.getLoanList({
            limit: limit,
            page: value,
            filter: filters,
        }).then((res) => {
            if (res.status === 200 && res.data) {
                let num = (value - 1) * limit;
                const loans = res.data.map((item) => ({
                    key: res.data.indexOf(item) + 1,
                    number: res.data.indexOf(item) + num + 1,
                    accNumber: item.account_number,
                    salary: currencyNoPrefix(item.salary),
                    employeeNumber: item.employee_number,
                    name: item.employee_name,
                    department: item.department,
                    date: dateLocaleStringFormat(item.transaction_date),
                    amount: currencyNoPrefix(item.loan_amount),
                    loanId: item.loan_code,
                    loanType: snakeCaseToCapital(item.loan_type),
                    installmentNo: item.installment_no,
                    tenure: item.tenure,
                    status: (
                        <Tag color={getLoanStatusClass(item.status)}>
                            {item.status}
                        </Tag>
                    ),
                }));
                setDataLoan(loans);
                setTotalLoan(res.meta.total_item);
            }
        });
    }

    function getLoanStatusClass(status) {
        switch (status) {
            case "approved":
                return "blue";
            case "paid_off":
                return "lime";
            case "disbursed":
                return "green";
            case "rejected":
                return "red";
            case "overdue":
                return "default";
            default:
                return "warning";
        }
    }

    //component filterStatus

    const FilterStatus = () => (
        <Select style={{ width: 100 }} onChange={handleFilterStatus}>
            <Option value=""></Option>
            <Option value="approved">approved</Option>
            <Option value="paid_off">paid off</Option>
            <Option value="disbursed">disbursed</Option>
            <Option value="rejected">rejected</Option>
            <Option value="overdue">overdue</Option>
            <Option value="applied">applied</Option>
        </Select>
    );

    //component filterLoanType
    const FilterLoanType = () => (
        <Select style={{ width: 100 }} onChange={handleFilterLoanType}>
            <Option value=""></Option>
            <Option value="salary_advance">Salary Advance</Option>
            <Option value="installment">Installment</Option>
        </Select>
    );

    /** define table's header */
    const LoanHead = [
        {
            title: t("employee:label.no"),
            dataIndex: "number",
            key: "number",
            fixed: "left",
            width: 70,
        },
        {
            title: t("employee:label.accountNumber"),
            dataIndex: "accNumber",
            key: "accNumber",
            fixed: "left",
            width: 150,
        },
        {
            title: t("employee:label.salary"),
            dataIndex: "salary",
            key: "salary",
            width: 150,
        },
        {
            title: t("employee:label.employeeNumber"),
            dataIndex: "employeeNumber",
            key: "employeeNumber",
            width: 180,
        },
        {
            title: t("employee:label.employeeName"),
            dataIndex: "name",
            key: "name",
            width: 200,
        },
        {
            title: t("employee:label.department"),
            dataIndex: "department",
            key: "department",
            width: 150,
        },
        {
            title: t("employee:label.transactionDate"),
            dataIndex: "date",
            key: "date",
            width: 150,
        },
        {
            title: t("employee:label.loanAmount"),
            dataIndex: "amount",
            key: "amount",
            width: 150,
        },
        {
            title: t("employee:label.loanId"),
            dataIndex: "loanId",
            key: "loanId",
            width: 150,
        },
        {
            title: t("employee:label.loanType"),
            dataIndex: "loanType",
            key: "loanType",
            width: 150,
        },
        {
            title: t("employee:label.installmentNo"),
            dataIndex: "installment_no",
            width: 200,
            key: "installment_no",
            render: (text, record, installment) =>
                record.key !== 0 ? (
                    <span>
                        {record.loanType === "Installment"
                            ? record.installmentNo + " / " + record.tenure
                            : ""}
                    </span>
                ) : (
                    ""
                ),
        },
        {
            title: t("employee:label.status"),
            dataIndex: "status",
            width: 150,
            fixed: "right",
            key: "status",
        },
    ];

    useEffect(() => {
        let unmounted = false;
        loan.getLoanList({
            page: page,
            limit: limit,
            filter: filters,
        }).then((res) => {
            if (!unmounted && res.status === 200 && res.data) {
                const loans = res.data.map((item) => ({
                    key: res.data.indexOf(item) + 1,
                    number: res.data.indexOf(item) + 1,
                    accNumber: item.account_number,
                    salary: currencyNoPrefix(item.salary),
                    employeeNumber: item.employee_number,
                    name: item.employee_name,
                    department: item.department,
                    date: dateLocaleStringFormat(item.transaction_date),
                    amount: currencyNoPrefix(item.loan_amount),
                    loanId: item.loan_code,
                    loanType: snakeCaseToCapital(item.loan_type),
                    installmentNo: item.installment_no,
                    tenure: item.tenure,
                    status: (
                        <Tag color={getLoanStatusClass(item.status)}>
                            {item.status}
                        </Tag>
                    ),
                }));
                setDataLoan(loans);
                setTotalLoan(res.meta.total_item);
            }
            if (Object.keys(filters).length !== 0 && res.data === null) {
                message.error("No data found with the given filter(s)");
            }
        });

        return () => {
            unmounted = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    /** insert row of filters */
    dataLoan.unshift({
        key: 0,
        number: "",
        accNumber: (
            <Input
                type="text"
                name="account_number"
                onChange={(e) => handleFilter("accNumber", e)}
            />
        ),
        salary: "",
        employeeNumber: (
            <Input
                type="text"
                name="employee_number"
                onChange={(e) => handleFilter("employeeNumber", e)}
            />
        ),
        name: (
            <Input
                type="text"
                name="employee_name"
                onChange={(e) => handleFilter("name", e)}
            />
        ),
        department: (
            <Input
                type="text"
                name="department"
                onChange={(e) => handleFilter("department", e)}
            />
        ),
        date: "",
        amount: (
            <Input
                type="text"
                name="loan_amount"
                onChange={(e) => handleFilter("amount", e)}
            />
        ),
        loanId: (
            <Input
                type="text"
                name="loan_code"
                onChange={(e) => handleFilter("loanId", e)}
            />
        ),
        loanType: <FilterLoanType />,
        installmentNo: "",
        tenure: "",
        status: <FilterStatus />,
    });

    return (
        <Section>
            <div className="kwEmployee">
                <h2>{t("employee:title.loanList")}</h2>
                <MenuList activeKey="loan" />
                <Card>
                    <h3>{dataVendor.name}</h3>
                    <div className="kwEmployee__top-button">
                        <Button type="default" onClick={handleDownload}>
                            {t("employee:button.downloadReport")}
                        </Button>
                    </div>
                    <Table
                        columns={LoanHead}
                        dataSource={dataLoan}
                        pagination={false}
                        scroll={{ x: "calc(700px + 50%)" }}
                    />
                    <div className="pagination">
                        <Pagination
                            defaultPageSize={limit}
                            defaultCurrent={page}
                            current={page}
                            total={totalLoan}
                            onChange={handleChangePage}
                            showSizeChanger={false}
                        />
                    </div>
                </Card>
            </div>
        </Section>
    );
}

export default LoanListContent;
