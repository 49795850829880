import PATH_URL from "./path";
import MainLayout from "../layouts/MainLayout";
import LoginLayout from "../layouts/LoginLayout";
// import Home from "../pages/Home";
import Help from "../pages/Help";
import Login from "../pages/Login";
import Setting from "../pages/Setting";
import SuccessFee from "../pages/SuccessFee";
import SuccessFeeDetail from "../pages/SuccessFeeDetail";
import EmployeeList from "../pages/EmployeeList";
import EmployeeDetail from "../pages/EmployeeDetail";
import EmployeeNew from "../pages/EmployeeNew";
import Partnership from "../pages/Partnership";
import InvoiceList from "../pages/InvoiceList";
import InvoiceDetail from "../pages/InvoiceDetail";
import LoanList from "../pages/LoanList";
import Discrepancy from "../pages/Discrepancy";
import DiscrepancyUpload from "../pages/DiscrepancyUpload";
import DiscrepancyDetail from "../pages/DiscrepancyDetail";
import Promo from "../pages/Promo";

const routes = [
  {
    path : PATH_URL.LOGIN,
    component: Login,
    layout: LoginLayout,
    needAuthenticated: false
  },
  {
    path : PATH_URL.PARTNERSHIP,
    component: Partnership,
    layout: LoginLayout,
    needAuthenticated: false
  },
  {
    path : PATH_URL.HOME,
    component: EmployeeList,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.SETTING,
    component: Setting,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.SUCCESS_FEE,
    component: SuccessFee,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.SUCCESS_FEE_DETAIL,
    component: SuccessFeeDetail,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.HELP,
    component: Help,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.EMPLOYEE_LIST,
    component: EmployeeList,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.EMPLOYEE_DETAIL,
    component: EmployeeDetail,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.EMPLOYEE_ADD_NEW,
    component: EmployeeNew,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.INVOICE_LIST,
    component: InvoiceList,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.INVOICE_DETAIL,
    component: InvoiceDetail,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.LOAN_LIST,
    component: LoanList,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.DISCREPANCY,
    component: Discrepancy,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.DISCREPANCY_UPLOAD,
    component: DiscrepancyUpload,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.DISCREPANCY_DETAIL,
    component: DiscrepancyDetail,
    layout: MainLayout,
    needAuthenticated: true
  },
  {
    path : PATH_URL.PROMO,
    component: Promo,
    layout: MainLayout,
    needAuthenticated: true
  }
];

export default routes;

