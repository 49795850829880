import styled from "styled-components";

export const Section = styled.div`
  .modal-wrapper {    
    font-family: 'Roboto', 'Helvetica';
    font-style: normal;
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .top-button {
    text-align: right;
    margin: 15px 0;
    button {
      margin: 0 5px;
    }
  }
`;
