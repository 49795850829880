import React, { useState, useEffect, useRef } from "react";
import { Card,Row, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import AddEmployeeModal from "../../components/Modal/AddEmployeeModal";
import NoChangeModal from "../../components/Modal/NoChangeModal";
import NotificationModal from "../../components/Modal/NotificationModal";
import EmployeeListTable from "../../components/Table/EmployeeListTable";
import MenuList from "../../components/Menu/MenuList";
import partner from "../../repository/Partner";
import { getMaxApplyDate, getStatementDate } from "../../library/partnerPaydateHelper";

function EmployeeListContent(props) {
  const { t } = useTranslation();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [updateData, setUpdateData] = useState(0);
  const [startBlackout, setStartBlackOut] = useState("");
  const [endBlackout, setEndBlackout] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [maxApplyDate, setMaxApplyDate] = useState("");
  const [statementDate, setStatementDate] = useState("");
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"))||"";
  const epUpdate = JSON.parse(window.localStorage.getItem("ep_update"));
  const [isNotification, setIsNotification] = useState(false);
  const lastDayNotification = "2022-02-11";

  const handleShow1 = () => {
    setShow1(true);
  }

  const isComponentMounted = useRef(false);

    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
        isComponentMounted.current = false;
        };
    }, []);

  useEffect(() => {
    partner.getPartnerProfile({})
      .then(res => {
        if (isComponentMounted.current && res.status === 200 && res.data) {
          let maxDate = res.data.payroll_start_date ? 
            getMaxApplyDate(res.data.payroll_start_date) : "";
          let salaryAdvStatement = res.data.payroll_start_date ? 
            getStatementDate(res.data.payroll_start_date) : "";
          let blackOutStart = res.data.payroll_start_date ? 
            getStatementDate(res.data.payroll_start_date) : "";
          let blackOutEnd = res.data.payroll_end_date ? 
            res.data.payroll_end_date : "";
          let due = res.data.payroll_end_date ?
            res.data.payroll_end_date : "";
          setDueDate(due);
          setMaxApplyDate(maxDate);
          setStatementDate(salaryAdvStatement);
          setStartBlackOut(blackOutStart);
          setEndBlackout(blackOutEnd);
        }
      });
    // Modal appear when partner has not updated employee date yet.
    if (!epUpdate || epUpdate.length === 0 ||
        (epUpdate.vendor !== dataVendor.vid) ||
        (epUpdate.vendor === dataVendor.vid && 
          (!dataVendor.monthly_update || dataVendor.monthly_update === "false"))
    ){
      Modal.confirm({
        title: t("employee:text.haveYouUpdateEmployee"),
        okText: t("employee:button.noHavent"),
        cancelText: t("employee:button.update"),
        okButtonProps: { type: 'primary', danger: true },
        cancelButtonProps: { type: 'primary' },
        onOk() {
          setShow2(true);
        },
        onCancel() {
          setShow1(true);
        },
      });
    }else{
      setIsNotification(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Section>
      <div className="kwEmployee">
        <h2>{t("employee:title.employeeList")}</h2>
        <MenuList activeKey="employee" />
        <Card>
          <Row>
            <div className="kwEmployee__top-description">
              <table className='table borderless'>
                <tbody>
                  <tr>
                    <td className="pull-right">{t("employee:label.dueDate")}</td>
                    <td>:</td>
                    <td className="paydate-text">{dueDate}</td>
                  </tr>
                  <tr>
                    <td className="pull-right">{t("employee:label.maxApplyDate")}</td>
                    <td>:</td>
                    <td className="paydate-text">{maxApplyDate}</td>
                  </tr>
                  <tr>
                    <td className="pull-right">{t("employee:label.salaryAdvanceStatDate")}</td>
                    <td>:</td>
                    <td className="paydate-text">{statementDate}</td>
                  </tr>
                  <tr>
                    <td className="pull-right">{t("employee:label.blackOutDate")}</td>
                    <td>:</td>
                    <td className="paydate-text">
                      {startBlackout} - {endBlackout}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <EmployeeListTable
            title={dataVendor.name}
            t={t}
            updateData={updateData}
            handleModal={handleShow1}
          />
        </Card>
      </div>

      {/* --- add employee modal --- */}
      <AddEmployeeModal 
        visible={show1}
        width={800}
        lang={t}
        handleUpdate={() => {setUpdateData(updateData + 1)}}
        onClose={() => {setShow1(false)}}
        setIsNotification={setIsNotification}
      />

      {/* --- add employee modal --- */}
      <NoChangeModal 
        visible={show2}
        width={500}
        lang={t}
        onClose={() => {setShow2(false)}}
        setIsNotification={setIsNotification}
      />

      {/* --- bulk upload template --- */}
      <NotificationModal 
        visible={isNotification}
        width={500}
        lang={t}
        setIsNotification={setIsNotification}
        lastDay={lastDayNotification}
      />

    </Section>
  );
};

export default EmployeeListContent;
