import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  Input,
  DatePicker,
  Switch,
  Select,
  InputNumber,
  message,
  Space
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import { probationOption, salaryOption } from "./dataOption";
import moment from "moment";
import partner from "../../repository/Partner";
import { getMaxApplyDate, getStatementDate } from "../../library/partnerPaydateHelper";
import { Section } from "./style";
import MaxPicEmailModal from "../../components/Modal/MaxPicEmailModal";
import NewPicEmailModal from "../../components/Modal/NewPicEmailModal";

function SettingContent(props) {
  const { t } = useTranslation(); 
  const lang = window.localStorage.getItem("language") || "en";
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));

  const [form] = Form.useForm();
  const [vendorId, setVendorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [primaryCp, setPrimaryCp] = useState("");
  const [establishedIn, setEstablishedIn] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [secondEmail, setSecondEmail] = useState("");
  const [probationPeriod, setProbationPeriod] = useState("");
  const [cutOff, setCutOff] = useState("");
  const [startDate, setStartDate] = useState("-");
  const [endDate, setEndDate] = useState("-");
  const [salaryType, setSalaryType] = useState("");
  const [amountLimit, setAmountLimit] = useState("");
  const [vaNumber, setVaNumber] = useState("");
  const [feeAbsorption, setFeeAbsorption] = useState(false);
  const [freezeStatus, setFreezeStatus] = useState("");
  const [dueDate, setDueDate] = useState("-");
  const [startBlackout, setStartBlackout] = useState("-");
  const [endBlackout, setEndBlackout] = useState("-");
  const [statementDate, setStatementDate] = useState("-");
  const [maxApplyDate, setMaxApplyDate] = useState("-");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAddEmail, setIsAddEmail] = useState(false);
  const [itemInputs, setItemInputs] = useState([]);
  const [showModalMax, setShowModalMax] = useState(false);
  const [showModalNew, setShowModalNew] = useState(false);
  const [picNumber, setPicNumber] = useState(0);
  const [otherPicEmail, setOtherPicEmail] = useState([]);

  function handleCloseModalMax() {
    setShowModalMax(false);
    setPicNumber(2)
  }
  function handleCloseModalNew() {
    setShowModalNew(false);
    window.location.reload();
  }
  function handleAddressChange({ target }) {
    setAddress(target.value);
  }
  function handleEmailChange({ target }) {
    setEmail(target.value);
  }
  function handlePhoneChange({ target }) {
    setPhoneNumber(target.value);
  }
  function handlePrimaryCpChange({ target }) {
    setPrimaryCp(target.value);
  }
  function handleSalaryTypeChange(value) {
    setSalaryType(value);
  }
  function handleFeeAbsorptionChange(value) {
    setFeeAbsorption(value);
  }
  function handleAmountLimitChange(value) {
    setAmountLimit(value);
  }
  function handleProbationPeriodChange(value) {
    setProbationPeriod(value);
  }
  function handleEstablishedChange(date, dateString) {
    setEstablishedIn(dateString);
  }
  function handleEdit() {
    setIsDisabled(false);
    setIsAddEmail(true);
  }
  function handleAddOtherEmail() {
    setPicNumber(picNumber+1);
    if (picNumber < 2) {
      setOtherPicEmail(arr => [...arr, {email: '',status: '' }]);
    }
  }
  function handleDeleteOtherEmail(index) {
    let formVal = form.getFieldValue();
    let res = otherPicEmail;
    res.splice(index, 1); // when delete the first item, the second item will shift forwards
    setOtherPicEmail(res);
    setPicNumber(picNumber-1);
    // every field delete will reset deleted field's value to empty string
    // field pic-email-{idx} is NEEDED to trigger validation
    formVal[`pic-email-0`] = picNumber === 1 ? '' : otherPicEmail[0].email;
    formVal[`pic-email-1`] = '';
  }
  const handlePicEmailChange = index => e => {
    let newArr = [...otherPicEmail];
    newArr[index].email = e.target.value; // change the target email (based on index)
    setOtherPicEmail(newArr);
  }
  function handleSubmit() {
    let otherEmails = [];
    if (otherPicEmail.length !== 0) {
      otherEmails = otherPicEmail.map(i => i.email);
    }

    setLoading(true);
    let data = {
      company_name: companyName,
      address: address,
      pic_name: primaryCp,
      pic_phone_number: phoneNumber,
      pic_email: email,
      probation_period: probationPeriod,
      payroll_cut_off_date: cutOff.toString(),
      salary_type: salaryType,
      company_monthly_limit: parseFloat(amountLimit),
      established_in: establishedIn,
      va_number: vaNumber,
      fee_absorption: feeAbsorption.toString(),
      freeze_status: freezeStatus,
      secondary_pic_email: secondEmail,
      other_pic_emails: otherEmails.join()
    }

    partner
      .putPartnerProfile({
        params: data,
        id: vendorId
      })
      .then(res => {
        if(res.status === 200) {
          message.success(t("setting:text.successUpdate"));
          let partner = JSON.stringify({
            vid: vendorId,
            name: companyName,
            email: email,
            monthly_update: dataVendor.monthly_update,
            cut_off: parseInt(
              cutOff
            )
          });
          window.localStorage.setItem("vnd", partner);
          setIsDisabled(true);
          setIsAddEmail(false);

          // after save/update the changes,
          // open NewPicEmailModal ONLY when there are changes on the other PIC email
          if (itemInputs.length === otherEmails.length && itemInputs.every((value, index) => value === otherEmails[index])) {
            window.location.reload()
          } else { setShowModalNew(true) }
          // if not, reload the page

        } else if(res.status === 403) {
          message.error(res.message[lang]);
        } else {
          message.error(res.errors[0].moreInfo);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    let unmounted = false;
    partner
      .getPartnerProfile({})
      .then(res => {
        if (!unmounted && res.status === 200 && res.data) {
          setVendorId(res.data.vendor_id);
          setCompanyName(res.data.company_name);
          setPrimaryCp(res.data.pic_name);
          setAddress(res.data.address);
          setEmail(res.data.pic_email);
          setSecondEmail(res.data.secondary_pic_email);
          if (res.data.other_pic_partner_list) {
            let otherEmails = res.data.other_pic_partner_list;
            setOtherPicEmail(otherEmails)
            setPicNumber(otherEmails.length)
            setItemInputs(otherEmails.map(i => i.email))
            form.resetFields()
          }
          setPhoneNumber(res.data.pic_phone_number);
          setProbationPeriod(res.data.probation_period);
          setVaNumber(res.data.va_number);
          setSalaryType(res.data.salary_type);
          setFreezeStatus(res.data.freeze_status);
          setCutOff(res.data.payroll_cut_off_date);
          setStartDate(res.data.payroll_start_date ? 
            res.data.payroll_start_date : startDate
          );
          setEndDate(res.data.payroll_end_date ?
            res.data.payroll_end_date : endDate);
          setDueDate(res.data.payroll_end_date ?
            res.data.payroll_end_date : dueDate
          );
          setStatementDate(res.data.payroll_start_date ?
            getStatementDate(res.data.payroll_start_date) : statementDate
          );
          setMaxApplyDate(res.data.payroll_start_date ?
            getMaxApplyDate(res.data.payroll_start_date) : maxApplyDate
          );
          setEndBlackout(res.data.payroll_end_date ?
            res.data.payroll_end_date : endBlackout
          );
          setStartBlackout(res.data.payroll_start_date ?
            getStatementDate(res.data.payroll_start_date) : startBlackout
          );
          setFeeAbsorption(
            res.data.fee_absorption === "true" ? true : false);
          setAmountLimit(
            res.data.company_monthly_limit === 0 ? 
            "0" : res.data.company_monthly_limit
          );
          setEstablishedIn(res.data.established_in);
        }
      });

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (picNumber > 2) {
      setShowModalMax(true)
    }
  }, [picNumber])

  return (
    <Section>

      {/* popup message max pic emails */}
      <MaxPicEmailModal 
        visible={showModalMax}
        width={600}
        lang={t}
        onClose={handleCloseModalMax}
      />

      <NewPicEmailModal
        visible={showModalNew}
        width={600}
        lang={t}
        onClose={handleCloseModalNew}
      />

      <div className="kwSetting">
        <h2>{t("setting:title.setting")}</h2>
        <Card>
          <h3>{t("setting:title.companyDetails")}</h3>
          <div className="kwSetting__top-button">
            <Button
              type="primary"
              onClick={handleEdit}
              loading={loading}
              disabled={loading}
            >
              {t("setting:button.editCompany")}
            </Button>
          </div>
          <Form form={form} onFinish={handleSubmit} initialValues={{"pic-email-0" : itemInputs[0] || '', "pic-email-1": itemInputs[1] || ''}}>
            <Row>
              <Col md={8} xs={24}>
                <Form.Item>
                  <span className="input-label">{t("setting:label.companyName")}</span>
                  <Input
                    type="text"
                    value={companyName}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.primaryCP")}</span>
                  <Input
                    type="text"
                    value={primaryCp}
                    onChange={handlePrimaryCpChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.establishedIn")}</span>
                  {establishedIn && (
                    <DatePicker
                      className="w-100"
                      allowClear={false}
                      defaultValue={moment(establishedIn)}
                      onChange={handleEstablishedChange}
                      disabled={isDisabled}
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.address")}</span>
                  <Input.TextArea
                    rows={3}
                    value={address}
                    onChange={handleAddressChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.phoneNumber")}</span>
                  <Input
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.emailAddress")}</span>
                  <Input
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    disabled
                  />
                </Form.Item>
                <span className="input-label ml-3">{t("setting:label.otherEmails")}</span>
                {otherPicEmail.map((item, idx) => (
                  <Space key={idx} align="baseline" style={{ display: "flex", marginBottom: 8 }}>
                    <Form.Item name={`pic-email-${idx}`} rules={[{ type: 'email', message: 'not a valid email' }]}>
                      <Input
                        type="text"
                        value={item.email}
                        onChange={handlePicEmailChange(idx)}
                        disabled={isDisabled || item.status === 'approved' ? true : false}
                        />
                    </Form.Item>
                    <DeleteOutlined
                      style={{ color: "red", fontSize: 17 }}
                      onClick={isAddEmail && item.status !== 'approved' ? (() =>  handleDeleteOtherEmail(idx)) : () => {}}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={handleAddOtherEmail}
                    block
                    icon={<PlusOutlined />}
                    disabled={!isAddEmail}
                  >
                    Add item
                  </Button>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item>
                  <span className="input-label">{t("setting:label.probationPeriod")}</span>
                  { probationPeriod && (
                    <Select
                      defaultValue={probationPeriod}
                      onChange={handleProbationPeriodChange}
                      disabled
                    >
                      {probationOption.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) }
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.startPayday")}</span>
                  <Input
                    type="text"
                    value={startDate + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.endPayday")}</span>
                  <Input
                    type="text"
                    value={endDate + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                {/* <Form.Item>
                  <span className="input-label">{t("setting:label.payrollCutOff")}</span>
                  { cutOff && (
                    <Select
                      defaultValue={cutOff}
                      onChange={handleCutOffChange}
                      disabled={isDisabled}
                    >
                      {cutOffOption.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item> */}
                <Form.Item>
                  <span className="input-label">{t("setting:label.salaryType")}</span>
                  { salaryType && (
                    <Select
                      defaultValue={salaryType}
                      onChange={handleSalaryTypeChange}
                      disabled
                    >
                      {salaryOption.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) }
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.companyAmountLimit")}</span>
                  {amountLimit && (
                    <InputNumber
                      type="text"
                      className="w-100"
                      defaultValue={amountLimit}
                      formatter={value => `${currencyNoPrefix(value)}`}
                      onChange={handleAmountLimitChange}
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("setting:label.vaNumber")}</span>
                  <Input
                    type="text"
                    value={vaNumber}
                    disabled
                  />
                </Form.Item>
                {/* <Form.Item>
                  <span className="input-label">{t("setting:label.companyFee")}</span>
                  <Row>
                    <Col sm={6}>
                      <Switch
                        checked={feeAbsorption}
                        onChange={handleFeeAbsorptionChange}
                        disabled={isDisabled}
                      />
                    </Col>
                    <Col sm={18}>
                      <p className="switch-text">{t("setting:text.switchDescription")}</p>
                    </Col>
                  </Row>
                </Form.Item> */}
              </Col>
              <Col md={8} xs={24}>
                <Form.Item>
                  <span className="input-label">{t("employee:label.dueDate")}</span>
                  <Input
                    type="text"
                    value={dueDate + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("employee:label.salaryAdvanceStatDate")}</span>
                  <Input
                    type="text"
                    value={statementDate + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("employee:label.maxApplyDate")}</span>
                  <Input
                    type="text"
                    value={maxApplyDate + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("employee:label.startBlackOutDate")}</span>
                  <Input
                    type="text"
                    value={startBlackout + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">{t("employee:label.endBlackOutDate")}</span>
                  <Input
                    type="text"
                    value={endBlackout + t("setting:label.ofTheMonth")}
                    disabled
                  />
                </Form.Item>
                <p className="notes-text">{t("setting:text.pleaseContactText")}</p>
                <div className="kwSetting__bottom-button">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={isDisabled || loading}
                  >
                    {t("setting:button.save")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Section>
  );
};

export default SettingContent;