import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import Icon, { 
  MenuOutlined,
  DownOutlined,
  PoweroffOutlined
} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useRootContext } from "../../hoc/RootContext";
import { Section } from "./style";
import { MenuItems } from "./menu";
import logoImg from "../../assets/images/logo.png";
import ButtonLanguage from "../../components/ButtonLanguage";


export default function MainLayout(props) {
  const { t } = useTranslation();
  const { handleLogout } = useRootContext();
  const [hidden, setHidden] = useState(false);
  const current = props.children.props.location.pathname;
  const vnd = JSON.parse(window.localStorage.getItem("vnd"))||"";

  const handleToggleMenu = () => {
    setHidden(!hidden);
  }

  const handleClickLogout = () => {
    handleLogout();
    window.location.href = "/";
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleClickLogout}>
        <PoweroffOutlined />
        <span >
          {t("common:label.logout")}
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Section>
      <div className="kw-mainLayout-wrapper">
        <div className="navbar navbar-inverse navbar-fixed-top main-header">
          <figure className="main-header__logo">
            <img src={logoImg} alt="" />
          </figure>
          <div className="toggle-menu" onClick={handleToggleMenu}>
            <MenuOutlined />
          </div>
          
          <ul className="nav justify-content-end mr-5">
            <li className="nav-item mr-3">
            <Dropdown overlay={menu}>
              <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {vnd.email ? vnd.email : t("common:label.myAccount")}
                {" "}<DownOutlined />
              </span>
            </Dropdown>

            </li>
            <li className="nav-item">
              <ButtonLanguage />
            </li>
          </ul>                 
        </div>

        <aside className={`sidebar main-sidebar ${ hidden ? 'hide-menu' : ''}`}>
          <section className="main-sidebar__menu">
            <ul className="menu">
              {MenuItems.map(item => (
                <li 
                  key={item.key}
                  className={
                    current.includes(item.key) || item.path === current 
                    ? 'active' : ''
                  }
                >
                  <Link to={item.path}>
                    <Icon component={item.icon} />
                    <span className="menu-label">{t(item.label)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        </aside>
        <div className={`content-wrapper ${hidden ? 'full-width' : ''}`}>
          {props.children}
        </div>        
      </div>
    </Section>
  );
}
