import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  .button-koinworks {
    width: 100%;
    height: 48px;
    background: #2B486D;
    border-radius: 3px;
    color: #FFFFFF !important;
    font-weight: bold !important;
  }

  .kw-button-not-disabled{
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
    width: 100%;
  }

  .kw-button-disabled {
    width: 100%;
    background: #C4C4C4 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 3px;
  }
  /*------------------------------------*\
    # Fonts
  \*------------------------------------*/

  /*------------------------------------*\
    # Global
  \*------------------------------------*/
    html {
      box-sizing: border-box;
      height: 100%;
    }
    *, *:before, *:after {
      box-sizing: inherit;
    }

    body {
      height: 100%;
      min-height: 100%;
      font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #575756;
    }

    h1, h2, h3, h4, h5 {
      color: #2B486D;
      margin: 0;
    }

    h1 {
      font-weight: 600;
      font-size: 28px;
      line-height: normal;
      margin: 0 0 20px;
    }
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: normal;
      margin: 0 0 20px;
    }
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: normal;
      margin: 0 0 20px;
    }
    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      margin: 0 0 10px;
    }
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
      margin: 0 0 10px;
    }
    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      margin: 0 0 10px;
    }

    a, * > a, a:hover, a:focus {
      text-decoration: none;
      outline: none;

      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -ms-transition:all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
    }
    a {
      font-size: 1em;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    img {
      max-width: 100%;
    }
    iframe {
      max-width: initial !important;
    }
    #root {
      height: 100%;
    }
    button {
      border-radius: 3px !important;
      font-weight: bold !important;
      text-transform: uppercase;
    }

  /*------------------------------------*\
  # Custom Ant
  \*------------------------------------*/
    .ant-layout {
      background: #F2F2F2;
    }
    .ant-btn {
      color: #8E8E8E;
      img {
        transition: all 0.2s ease-in;
      }

      &:hover {
        color: #378DF4;
        border-color: #378DF4;

        img {
          filter: invert(45%) sepia(55%) saturate(2369%) hue-rotate(195deg) brightness(99%) contrast(93%);
        }
      }

      &:focus {
        color: #378DF4;
        border-color: #378DF4;

        img {
          filter: invert(45%) sepia(55%) saturate(2369%) hue-rotate(195deg) brightness(99%) contrast(93%);
        }
      }

      i {
        width: 14px;
        margin-right: 8px;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &-link:hover {
        border-color: transparent;
      } 
    }

    .ant-btn-primary {
      background-color: #2B486D;
      border-color: #2B486D;
      color: #fff;
      &:hover, &:focus {
        background-color: #2B486D;
        border-color: #2B486D;
        color: #fff;
        opacity: 0.8;
      }
    }
    .ant-btn-link[disabled], .ant-btn-link[disabled]:hover {
      color: #566068;
    }
    .ant-btn-dangerous {
      background: #C53741;
      border: 1px solid #C53741;
      color: #fff;
      &:hover {
        color: #C53741;
        border-color: #C53741;

        img {
          filter: invert(45%) sepia(55%) saturate(2369%) hue-rotate(195deg) brightness(99%) contrast(93%);
        }
      }
    }
    .ant-btn-success {
      background: #41af60;
      border: 1px solid #41af60;
      color: #fff;
    }
    
    .ant-popover {
      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner-content {
        padding: 0;
      }
      .kwPopoverSort {
        padding: 12px 16px;
        width: 250px;
      }
    }

    .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
      padding-top: 0;
    }

    .ant-popover-placement-bottom {
      padding-top: 30px;
      padding-left: 24px;
    }

    .ant-drawer {
      &.ant-drawer-open {
        .ant-drawer-content-wrapper {
          right: 0;
        }
      } 
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          overflow: hidden;
          border-radius: 8px 8px 0px 0px;
          position: initial;
          .ant-drawer-body {
            padding: 0;
          }
        }
      }
    } 

    .ant-select-dropdown-menu-item {
			color: #378DF4;
    }

    .ant-table-thead > tr > th {
      background-color: #FAFAFA;
      font-weight: bold;
    }
  
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #DEE2E5;
    }
  
    .ant-table-tbody a {
      color: #378DF4;	
    }
    
    .ant-table-tbody > tr > td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    
    td.column-amount {
      text-align: right !important;
    }

    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link, .ant-pagination-item {
      border: 0;
    }
  
    .ant-pagination-item-active a {
      color: #378DF4;
    }

    .ant-message .anticon {
      top: -3px;
    }

    /*------------------------------------*\
    # Alignment
    \*------------------------------------*/
    .pull-right {
      text-align: right;
    }
    .text-center {
      text-align: center;
    }

    /*------------------------------------*\
    # Size
    \*------------------------------------*/
    .w-100 {
      width: 100%;
    }
`;

export default GlobalStyle;