import { apiGetWithToken, apiPutWithToken, apiPostWithoutToken, apiPostWithToken } from "../../services/api";
import { PATH_PARTNER } from "../../services/path/partner";

async function getPartnerProfile(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_PARTNER.PARTNER_ACCOUNT, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function putPartnerProfile(props) {
  let params = props.params;
  let id = props.id;
  let response = "";
  try {
    response = await apiPutWithToken(PATH_PARTNER.PARTNER_UPDATE + id, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function putMonthlyUpdateEmployee(props) {
  let id = props.id;
  let params = {
    company_monthly_employee_update: props.status
  }
  let response = "";
  try {
    response = await apiPutWithToken(PATH_PARTNER.PARTNER_UPDATE + id, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function postNewPartner(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiPostWithoutToken(PATH_PARTNER.PARTNER_REGISTRATION, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getBcaTemplate(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_PARTNER.PARTNER_BCA_TEMPLATE, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getCurrentLoanReport(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_PARTNER.PARTNER_DOWNLOAD_CURRENT, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}
async function downloadLoanReportByInvoice(props) {
  let invoice = props.invoice;
  let response = "";
  try {
    response = await apiGetWithToken(PATH_PARTNER.PARTNER_DOWNLOAD_INVOICE+'/'+invoice );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function postFeedback(props) {
  let params = props.params;
  let response = "";
  try {
    response = await apiPostWithToken(PATH_PARTNER.PARTNER_FEEDBACK, params);
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}


const partner = {
  getPartnerProfile : getPartnerProfile,
  putPartnerProfile : putPartnerProfile,
  putMonthlyUpdateEmployee : putMonthlyUpdateEmployee,
  postNewPartner : postNewPartner,
  getBcaTemplate : getBcaTemplate,
  getCurrentLoanReport : getCurrentLoanReport, 
  downloadLoanReportByInvoice :downloadLoanReportByInvoice,
  postFeedback : postFeedback
};

export default partner;
