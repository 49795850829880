import React from 'react';
import SuccessFeeDetails from '../../containers/SuccessFeeDetail';

function SuccessFeeDetail(props) {
    return (
        <React.Fragment>
            <SuccessFeeDetails />
        </React.Fragment>
    );
}

export default SuccessFeeDetail;