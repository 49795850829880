import React from "react";
import { Row, Col } from "antd";
import ButtonLanguage from "../../components/ButtonLanguage";
import { Section } from "./style";

import logoImg from "../../assets/images/koinworks-logo.png";
import bgImg from "../../assets/images/login/background.svg";

export default function LoginLayout(props) {
  return (
    <Section>
      <Row>
        <Col md={12}>
          <figure className="bg-wrapper">
            <img
              className="img-bg"
              src={bgImg}
              alt=""
            />
          </figure>
          <figure className="bg-strip">
            {/* <img className="img-strip" src={stripImg} alt="" /> */}
          </figure>          
        </Col>
        <Col xs={24} md={12}>
          <div className="kw-login-layout-wrapper">
            <div className="navbar navbar-expand navbar-light">
              <figure className="navbar-brand">
                <img src={logoImg} alt="koinworks" />
              </figure>
              <div className="justify-content-end navbar-collapse collapse">
                <ButtonLanguage />
              </div>
            </div>
            <div className="bgcontent-wrapper">
              <div className="bg-content">
                {props.children}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  );
}
