import styled from "styled-components";

export const Section = styled.div`
  .modal-wrapper {    
    font-family: 'Roboto', 'Helvetica';
    font-style: normal;
  }
  .dropzone {
    background: #F4F4F4;
    width: 100%;
    padding: 20px 10px;
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
    }
    .error-message {
      font-size: 12px;
      font-weight: normal;
      color: #C53741;
    }
    .success-message {
      color: #0ba22b;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .dropzone-img {
    text-align: center;
  }
  .text-left.text-success-update-employe p {
    font-size: 12px;
    font-weight: normal;
    color: #2b486d;
  }
`;
