import React from "react";
import { Modal, Button} from "antd";
import { Section } from "./style";
import partner from "../../../repository/Partner";
// import ClevertapReact from "../../../library/clevertap";
import clevertap from 'clevertap-web-sdk';

const NoChangeModal = ({
    visible,
    width,
    lang,
    onClose,
    setIsNotification
  }) => {    
    const dataVendor = JSON.parse(window.localStorage.getItem("vnd"))||"";

    function handleOk() {
      window.localStorage.setItem("ep_update",
        JSON.stringify({
          vendor: dataVendor.vid
        })
      );
      let vendor = dataVendor;
      vendor.monthly_update = "true";
      window.localStorage.setItem("vnd",
        JSON.stringify(vendor)
      );
      partner
        .putMonthlyUpdateEmployee({
          id: dataVendor.vid,
          status: "true"
        })
        .then(res => {
          if (res.status === 200) {
            let currentDate = new Date();
            let eventData = {
              "Source": "Pop Up Notif",
              "Category": "Existing List",
              "Partner Name": dataVendor.name,
              "Date": currentDate,
              "Formatted Date": currentDate.getDate() + '/' +
                (currentDate.getMonth() + 1) + '/' +
                currentDate.getFullYear()
            };
            clevertap.onUserLogin.push({
              "Site": {
                "Name": dataVendor.name,
                "Email": dataVendor.email
              }
            });
            clevertap.event.push("Update Employee List", eventData);
          }
          return ;
      });
      onClose();
      setIsNotification(true);
    }    

    return (
      <Modal
        open={visible}
        width={width}
        title={lang("employee:title.confirmationPage")}
        maskClosable={false}
        onCancel={onClose}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={handleOk}
          >
            {lang("employee:button.confirm")}
          </Button>
        ]}
      >
        <Section>
          <div className="modal-wrapper">
          <h5 className="text-center">{lang("employee:title.confirmNoUpdate")}</h5>
          <p className="text-center">{lang("employee:text.byClickingConfirm")}</p>
          </div>
        </Section>
      </Modal>
    );
  }

  export default NoChangeModal;