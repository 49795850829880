import styled from "styled-components";

export const Section = styled.div`
	.kwSetting {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 20px;
      top: -12px;
      position: relative;
      button {
        margin: 0 10px;
      }
    }
    &__bottom-button {
      text-align: right;
      padding: 20px 15px 0;
      margin-top: 30px;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    }
    .ant-card {
      margin: 0 1%;
    }
    .ant-form {
      .ant-col {
        padding: 0 15px;
      }
      .input-label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-transform: uppercase;
        color: #000000
      }
    }
    .ant-switch {
      border-radius: 20px!important;
      margin: 10px;
      height: 30px;
      width: 100%;
    }
    .ant-switch::after {
      top: 0;
      width: 28px;
      height: 28px;
      margin-left: 0;
    }
    .ant-space {
      .ant-space-item:first-of-type {
        margin: 0!important;
        width: 92%;
        .ant-form-item {
          width: 100%;
        }
        .ant-form-item-control {
          padding: 0 5px 0 15px;
        }
      }
    }
    .switch-text {
      text-transform: uppercase;
      font-size: 11px;
      margin-top: 8px
    }
    .notes-text {
      margin-left: 20px;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
`;