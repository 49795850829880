import styled from "styled-components";

export const Section = styled.div`
	.kwEmployee {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 10px;
      top: -25px;
      position: relative;
      button {
        margin: 0 5px;
      }
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #566068;
      margin-bottom: 30px;
    }
    .ant-tabs {
      background: #ffffff;
      padding: 0 10px;
    }
    .ant-input {
      border-radius: 10px;
    }
    .btn-table {
      .ant-btn {
        margin: 4px;
        span {
          font-weight: 400;
          font-size: 12px;
        }
      }
      .default {
        color: #566068;
        border: 1px solid #566068;
      }
      .success {
        color: #41AF60;
        border: 1px solid #41AF60;
      }
      .danger {
        color: #9E2C34;
        border: 1px solid #9E2C34;
      }
      .primary {
        color: #115996;
        border: 1px solid #115996;
      }
      .greyWarning {
        color: #c3c3c3;
        border-color: #c3c3c3;
      }
      .disable {
        background: #efefef;
        cursor: no-drop;
        color: #c3c3c3;
        border-color: #c3c3c3;
      }
      .anticon {
        font-size: 15px;
        margin: 0;
      }
    }
    .row-delete {
      background: #cc111126;
    }
    .pagination {
      float: right;
      margin: 15px 0;
      padding: 10px;
    }
  }
`;