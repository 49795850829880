import React from 'react';
import InvoiceDetailContent from '../../containers/InvoiceDetail';

function InvoiceDetail(props) {
    return (
        <React.Fragment>
            <InvoiceDetailContent match={props.match} />
        </React.Fragment>
    );
}

export default InvoiceDetail;