import styled from "styled-components";

export const Section = styled.div`
  .kw-login-layout-wrapper {
    background: #f2f2f2;
  }
  .navbar-brand {
    img {
      width: 90%;
      margin-top: 10px;
    }
  }
  .bg-wrapper {
    height: 100vh;
    margin: 0;
    margin-left: -15px;
    position: fixed;
    width: 100%;
    .img-acc {
      position: absolute;
      z-index: 9;
      bottom: 35px;
      left: 50px;
    }
    .img-bg {
      height: 100%;
      width: 51%;
      object-fit: cover;
    }
  }
  .bg-strip {
    z-index: 1;
    position: relative;
    text-align: right;
    right: 30px;
    top: 35px;
    .img-strip {
      width: 30px;
      height: auto;
    }
  }
  .bg-content {
    padding-top: 50px;
  }
  .bg-dot {
    position: fixed;
    top: 60vh;
    right: 55px;
    width: 120px;
  .img-dot {
    width: 100%;
  }
  .modal-wrapper {
    padding: 15px 0;
  }
  .kw-modal-img {
    margin: 35px 20px;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    .bg-strip {
      display: none;
    }
  }
`;
