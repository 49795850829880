const DISCREPANCY_CREATE = "koingaji/partner/discrepancy/create/";
const DISCREPANCY_UPLOAD = "koingaji/partner/discrepancy/upload";
const DISCREPANCY_LIST = "koingaji/partner/discrepancy/";
const DISCREPANCY_DETAIL = "koingaji/partner/discrepancy/detail/";

export const PATH_DISCREPANCY = {
  DISCREPANCY_CREATE : DISCREPANCY_CREATE,
  DISCREPANCY_UPLOAD : DISCREPANCY_UPLOAD,
  DISCREPANCY_LIST : DISCREPANCY_LIST,
  DISCREPANCY_DETAIL : DISCREPANCY_DETAIL
};