import React from 'react';
import LoginForm from '../../containers/LoginForm';

function Login(props) {
    return (
        <React.Fragment>
            <LoginForm/>
        </React.Fragment>
    );
}

export default Login;