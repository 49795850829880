import { apiPostWithToken, apiGetWithToken } from "../../services/api";
import { PATH_DISCREPANCY } from "../../services/path/discrepancy";


async function createDiscrepancy(props) {
  let params = props.params;
  let id = props.id;
  let response = '';
  try {
    response = await apiPostWithToken(
      PATH_DISCREPANCY.DISCREPANCY_CREATE + id, params
      );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function bulkUpload(props) {
  let params = props.params;
  let response = '';
  try {
    response = await apiPostWithToken(
      PATH_DISCREPANCY.DISCREPANCY_UPLOAD, params
      );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getList(props) {
  let id = props.id;
  let response = '';
  try {
    response = await apiGetWithToken(
      PATH_DISCREPANCY.DISCREPANCY_LIST + id
      );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

async function getDetail(props) {
  let id = props.id;
  let year = props.year;
  let month = props.month;
  let response = '';
  try {
    response = await apiGetWithToken(
      PATH_DISCREPANCY.DISCREPANCY_DETAIL + id + '?year=' + year + '&month=' + month
      );
  } catch (error) {
    response = error.response;
  }
  return response && response.data;
}

const discrepancy = {
  createDiscrepancy : createDiscrepancy,
  bulkUpload : bulkUpload,
  getList : getList,
  getDetail : getDetail
};

export default discrepancy;
