import React from "react";
import { Card, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";

function SuccessFeeContent(props) {
  const { t } = useTranslation(); 

  const FeeHead = [
    { title: t("fee:label.no"), dataIndex: 'number', key: 'number' },
    { title: t("fee:label.invoice"), dataIndex: 'invoice', key: 'invoice' },
    { title: t("fee:label.loanAmount"), dataIndex: 'amount', key: 'amount' },
    { title: t("fee:label.numberOfBorrower"), dataIndex: 'borrower', key: 'borrower' },
    { title: t("fee:label.successFee"), dataIndex: 'fee', key: 'fee' },
    { title: t("fee:label.period"), dataIndex: 'period', key: 'period' }
  ];

  return (
    <Section>
      <div className="kwEmployee">
        <h2>{t("fee:title.successFee")}</h2>
        <Card>
          <h3>Company Name</h3>
          <Table columns={FeeHead} />
        </Card>
      </div>
    </Section>
  );
};

export default SuccessFeeContent;
