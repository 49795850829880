import styled from "styled-components";

export const Section = styled.div`
	.kwRegisterWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-card {
      width: 90%;
      margin: 0 auto;
    }
    .ant-card-bordered {
      border: unset;
      margin-bottom: 20px;
      padding-bottom: 25px;
    }
    &__top-section {  
      margin-bottom: 30px;
      padding-bottom: 10px;    
      p {
        width: 90%;
      }
    }
    &__form-section {
      padding-bottom: 10px;
    }
    .section-title {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      color: #2D2D2D;
    }
    .input-label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #071E35;
    }
    input {
      margin-top: 5px;
    }
    .ant-btn-primary {
      position: absolute;
      bottom: 26px;
      right: 0;
      float: right;
    }
    .error-validation {
      color: #ff4d4f;
      margin: 0;
      padding: 0;
    }
  }
  @media (max-width: 767px) {
    .ant-btn-primary {
      top: 0!important;
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    .ant-btn-primary {
      bottom: -30px!important;
    }
  }
`;