export function pageSizePagination(totalData) {
    // set default nearest number perpage
    const perPageDefault = ['10', '20', '50', '100', '500', '1000', '2500', '5000', '7500', '10000'];

    // round up from total data
    totalData = totalData % 10 !== 0 ? parseInt(totalData / 10) * 10 + 10 : totalData;

    const totalPageData = perPageDefault.filter((page) => page < totalData);
    totalPageData.push(String(totalData));

    return totalPageData;
}
