export const dateLocaleStringFormat = date => {
  let formatDate = new Date(date);
  
  return formatDate.toLocaleDateString("ID-id",
        {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      );
}

export const dateISOtoStringFormat = isoDate => {
  let date = new Date(isoDate);
  
  return date.getFullYear() + '-'
        + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
        +("0" + date.getDate()).slice(-2);
}

export const getShortMonth = idx => {
  let months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  return months[idx];
}