import moment from "moment";

export const decryptToken = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  return JSON.parse(jsonPayload);
};

export const expiredToken = (token) => {
  let maxAge = 24 * 3600;
  const decodedString = decryptToken(token);
  const now = moment();
  const expired = moment.unix(decodedString.exp);
  const duration = parseInt(moment.duration(expired.diff(now)).asSeconds()); // in seconds
  maxAge = (duration > maxAge) ? maxAge : duration;
  return maxAge; 
}