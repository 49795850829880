import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import { useEffect } from "react";

export default function ButtonLanguage() {
  const { i18n } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState();

  useEffect(() => {
    setLanguageSelected(i18n.language);
    // eslint-disable-next-line
  }, [languageSelected]);

  const language = [
    {
      languageId: "en",
      languageText: "En"
    },
    {
      languageId: "id",
      languageText: "ID"
    }
  ];

  const changeLanguage = lng => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };

  const languages = language.map((lang, index) => {
    return (
      <span
        key={index}
        className={`kw-docs-button-language ${
          languageSelected === lang.languageId ? "active" : "notActive"
        }`}
        onClick={() => changeLanguage(lang.languageId)}
      >
        {lang.languageText.toUpperCase()}
      </span>
    );
  });

  return <Section>{languages}</Section>;
}
