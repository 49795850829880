import React from 'react';
import EmployeeListContent from '../../containers/EmployeeList';

function EmployeeList(props) {
    return (
        <React.Fragment>
            <EmployeeListContent/>
        </React.Fragment>
    );
}

export default EmployeeList;