import styled from "styled-components";

export const Section = styled.div`
  .modal-wrapper {    
    font-family: 'Roboto', 'Helvetica';
    font-style: normal;
  }
  .modal-img {
    text-align: center;
    margin: 10px 10px 30px 10px;
  }
  h4 {
    text-align: center
  }
`;
