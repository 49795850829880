import React from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import history from "../../routers/history";
import PATH_URL from "../../routers/path";

const MenuList = ({
  activeKey
}) => {
  const { t } = useTranslation();

  return (
    <Menu mode="horizontal" selectedKeys={activeKey}>
      <Menu.Item
        key="employee"
        onClick={() => history.push(PATH_URL.EMPLOYEE_LIST)}
      >
        <span>
          {t("employee:label.employee")}
        </span>
      </Menu.Item>
      <Menu.Item
        key="loan"
        onClick={() => history.push(PATH_URL.LOAN_LIST)}
      >
        <span>
          {t("employee:label.loan")}
        </span>
      </Menu.Item>
      <Menu.Item
        key="invoice"
        onClick={() => history.push(PATH_URL.INVOICE_LIST)}
      >
        <span>
          {t("employee:label.invoice")}
        </span>
      </Menu.Item>
      <Menu.Item
        key="discrepancy"
        onClick={() => history.push(PATH_URL.DISCREPANCY)}
      >
        <span>
          {t("employee:label.discrepancy")}
        </span>
      </Menu.Item>
    </Menu>
  );
};

export default MenuList;