import React from 'react';
import HelpContent from '../../containers/Help';

function Help(props) {
    return (
        <React.Fragment>
            <HelpContent/>
        </React.Fragment>
    );
}

export default Help;