import React from 'react';
import SettingContent from '../../containers/Setting';

function Setting(props) {
    return (
        <React.Fragment>
            <SettingContent/>
        </React.Fragment>
    );
}

export default Setting;