import styled from "styled-components";

export const Section = styled.div`
	.kwEmployee {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 10px;
      top: -25px;
      position: relative;
      button {
        margin: 0 5px;
      }
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #566068;
      margin-bottom: 30px;
    }
    .ant-tabs {
      background: #ffffff;
      padding: 0 10px;
    }
  }
`;