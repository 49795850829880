import styled from "styled-components";

export const Section = styled.div`
	.kwDiscrepancy {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 10px;
      top: -25px;
      position: relative;
      button {
        margin: 0 5px;
      }
    }
    &__bottom-button {
      padding-bottom: 20px;
      button {
        margin-right: 5px;
      }
    }
    &__text-content {
      padding-top: 10px;
      padding-right: 25px;
      padding-bottom: 15px;
      p {
        font-size: 16px;
        padding-bottom: 10px;
        color: #071E35;
        strong {
          font-size: 19px;
        }
      }
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 27px;
      color: #566068;
      margin-bottom: 30px;
    }
    
  }
`;