const HOME = "/";
const LOGIN = "/login";
const PARTNERSHIP = "/partnership";
const DASHBOARD = '/dashboard';
const SETTING = '/setting';
const EMPLOYEE_LIST = '/list/employee';
const EMPLOYEE_DETAIL = '/list/employee/detail/:employeeId(\\d+)';
const EMPLOYEE_ADD_NEW = '/list/employee/new';
const LOAN_LIST = "/list/loan";
const INVOICE_LIST = "/list/invoice";
const INVOICE_DETAIL = '/list/invoice/detail/:id(\\d+)/:code([\\w&-]+)?';
const DISCREPANCY = '/list/discrepancy';
const DISCREPANCY_UPLOAD = '/list/discrepancy/upload';
const DISCREPANCY_DETAIL = '/list/discrepancy/detail/:month';
const HELP = '/help';
const SUCCESS_FEE = '/success-fee';
const SUCCESS_FEE_DETAIL = '/success-fee/detail';
const PROMO = '/promo';

const PATH_URL = {
  HOME : HOME,
  LOGIN : LOGIN,
  PARTNERSHIP : PARTNERSHIP,
  DASHBOARD : DASHBOARD,
  SETTING : SETTING,
  EMPLOYEE_DETAIL: EMPLOYEE_DETAIL,
  EMPLOYEE_LIST: EMPLOYEE_LIST,
  EMPLOYEE_ADD_NEW : EMPLOYEE_ADD_NEW,
  HELP : HELP,
  SUCCESS_FEE : SUCCESS_FEE,
  LOAN_LIST : LOAN_LIST,
  INVOICE_LIST : INVOICE_LIST,
  INVOICE_DETAIL : INVOICE_DETAIL,
  SUCCESS_FEE_DETAIL : SUCCESS_FEE_DETAIL,
  DISCREPANCY : DISCREPANCY,
  DISCREPANCY_UPLOAD : DISCREPANCY_UPLOAD,
  DISCREPANCY_DETAIL : DISCREPANCY_DETAIL,
  PROMO : PROMO
}

export default PATH_URL;