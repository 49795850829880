import React from "react";
import { Modal } from "antd";
import { Section } from "./style";
import successImg from "../../../assets/images/login/success.png";

const SuccessLoginModal = ({
    visible,
    width,
    lang,
    onClose
  }) => {

    return (
      <Modal
        open={visible}
        width={width}
        onCancel={onClose}
        footer={null}
      >
        <Section>
          <div className="modal-wrapper">
            <figure className="modal-img">
              <img src={successImg} alt="" />
            </figure>
            <h3>{lang("common:title.loginModal")}</h3>
            <p className="text-center">{lang("common:text.loginModal")}</p>
          </div>
        </Section>
      </Modal>
    );
  }

  export default SuccessLoginModal;