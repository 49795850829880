import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    .ant-modal-header {
        background-color: #c53741;

        .ant-modal-title {
            font-weight: bold;
            color: #fff;
            text-align: center;
        }
    }
`;
