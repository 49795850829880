import styled from "styled-components";

export const Section = styled.div`
	.kwLoginWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-card {
      width: 80%;
      margin: 0 auto;
    }
    .ant-card-bordered {
      border: unset;
      margin-bottom: 20px;
    }
    &__top-content {
      margin: 10% 1% 2%;
      padding: 0 5%;
      .page-title {
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        color: #2D2D2D;
      }
    }
    &__bottom-content {
      .bottom-img {
        float: left;
        width: 18%;
        margin-right: 5%;
        img {
          width: 100%;
        }
      }
      .bottom-text {
        float: left;
        width: 75%;        
        color: #2D2D2D;
        h5 {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: #2D2D2D;
        }
      }
    }
    .input-label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #071E35;
    }
    input {
      margin-top: 5px;
    }
    .kwCheckAgreement {
      .ant-checkbox-wrapper {
        float: left;
        span {
          &:last-child {
            font-size: 13px;
          }
        }
      }
      p {
        font-size: 15px;
        margin-left: 10px;
        margin-bottom: 0;
        float: left;
        text-align: justify;
      }
    }
    .text-link {
      color: #071E35;
      text-align: center;
      text-decoration: underline;
      size: 15px;
      line-height: 3;
    }
    .kw-error-message {
      color: #C53741;
      font-size: 15px;
      text-align: center;
      margin: 10px 0 0 0;
    }
  }
`;