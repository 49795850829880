import React from 'react';
import { Button, message } from 'antd';
import { StyledModal } from './style';
import employee from '../../../repository/Employee';

const FreezeAllModal = ({
    visible,
    lang,
    setIsModalNotification,
    setLoading,
    vendor_id,
    selectedRowKeys,
    setSelectedRowKeys,
    option
}) => {
    const handleCancelFreezeAll = () => {
        setIsModalNotification(false);
    };

    const confirmFreezeAll = (vendor_id) => {
        let params = { employee_ids: selectedRowKeys, option }; // get all checked
        setIsModalNotification(false);
        setLoading(true);
        employee
            .putFreezeOptionEmployee({
                vendor_id: vendor_id,
                params: params,
            })
            .then((res) => {
                if (res.status === 200) {
                    setSelectedRowKeys([]);
                } else {
                    message.error(res.errors[0].userMessage);
                }
                setLoading(false);
            });
    };

    return (
        <StyledModal
            closable={false}
            open={visible}
            title={option === 'freeze' ? lang('employee:title.frozenModal') : lang('employee:title.unfrozenModal')}
            footer={[
                <Button key="back" onClick={handleCancelFreezeAll}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={(e) => confirmFreezeAll(vendor_id)}>
                    Confirm
                </Button>,
            ]}
        >
            <p>{option === 'freeze' ? lang('employee:text.frozenModal') : lang('employee:text.unfrozenModal')}</p>
        </StyledModal>
    );
};

export default FreezeAllModal;
