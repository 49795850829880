import React from "react";
import { Card, Row, Col, Input, Collapse, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";

function HelpContent(props) {
  const { t } = useTranslation(); 
  const { Panel } = Collapse;

  return (
    <Section>
      <div className="kwHelp">
        <h2>{t("help:title.help")}</h2>
        <Card>
          <Row>
            <Col xs={24} md={12}>
              <p>{t("help:text.weWouldAssist")}</p>
              <Input.Search
                placeholder={t("help:label.searchIssue")}
                style={{ width: 200 }}
              />

              <div className="content-block">
                <h2>{t("help:title.gettingStarted")}</h2>
                <Collapse accordion>
                  <Panel header={t("help:text.howToActivate")} key="1">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToRegister")} key="2">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToAddEmployee")} key="3">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToAddMultipleEmployee")} key="4">
                    <p></p>
                  </Panel>
                </Collapse>
              </div>
              <div className="content-block">
                <h2>{t("help:title.managingEmployee")}</h2>
                <Collapse accordion>
                  <Panel header={t("help:text.howToViewOutstanding")} key="1">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToSomething")} key="2">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToRemoveEmployee")} key="3">
                    <p></p>
                  </Panel>
                  <Panel header={t("help:text.howToReAdd")} key="4">
                    <p></p>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>{t("help:title.cannotFind")}</h2>
              <p>{t("help:text.youHaveSpendTime")} <br/> {t("help:text.callUs")}</p>
              <p>
                PT. Lunaria Annua Teknologi<br/>
                Jl. HR Rasuna Said. Blok X5 No 13 Kuningan, Setia Budi<br />
                Jakarta Selatan 12950 <br />
                Indonesia
              </p>
              <Button type="primary">{t("help:button.callSupport")}</Button>
              <Button type="primary">{t("help:button.sendEmail")}</Button>
            </Col>
          </Row>
        </Card>
      </div>
    </Section>
  );
};

export default HelpContent;
