import React, { useEffect } from "react";
import { useRootContext } from "../../hoc/RootContext";

export default function AppLayout(props) {
    const { isAuthenticated, history } = useRootContext();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.needAuthenticated && !isAuthenticated) {
            history.push("/login");
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.needAuthenticated && !isAuthenticated) {
        return null;
      } else {
        return (
          <React.Fragment>
            {props.children}
          </React.Fragment>
        );
    }        
}