import React, { useState, useEffect } from "react";
import { Table, Tag, Input, Pagination, Select } from "antd";
import loan from "../../repository/Loan";
import { currencyNoPrefix } from "../../library/currencyFormatter";

function LoanHistory ({ t, loanId }) {  
  const [dataLoan, setDataLoan] = useState([]);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState(0);
  const limit = 10;
  const { Option } = Select;

  /** define table's header */
  const Columns = [
    { 
      title: t("employee:label.no"), 
      dataIndex: 'number',
      key: 'number' 
    },
    { 
      title: t("employee:label.loanId"), 
      dataIndex: 'loanId', 
      key: 'loanId' 
    },
    {
      title: t("employee:label.loanAmount"), 
      dataIndex: 'amount', 
      key: 'amount' 
    },
    { 
      title: t("employee:label.status"), 
      dataIndex: 'status', 
      key: 'status' 
    }
  ];

  const FilterStatus = () => (
    <Select style={{ width: 100 }} onChange={handleFilterStatus}>
      <Option value=""></Option>
      <Option value="approved">approved</Option>
      <Option value="paid_off">paid off</Option>
      <Option value="disbursed">disbursed</Option>
      <Option value="rejected">rejected</Option>
      <Option value="overdue">overdue</Option>      
      <Option value="applied">applied</Option>
    </Select>
  );

  function handleFilter({ target }) {
    let term = target.value;
    let terms = filters;
    if (term.length > 1) {
      terms[target.name] = "&" + target.name + "=" + term;
      setFilters(terms);
      setPage(1);
      setSearch(search + 1);
    }
    if (term.length === 0) {
      let key = target.name;
      delete terms[key];
      setFilters(terms);
      if (search > 0) {
        setSearch(search - 1);
      }
    }
  }

  function handleFilterStatus(value) {
    let name = "status";
    let terms = filters;
    if (value !== "") {
      terms[name] = "&" + name + "=" + value;
      setFilters(terms);
      setPage(1);
      setSearch(search + 1);
    }
    else {
      let key = name;
      delete terms[key];
      setFilters(terms);
      if (search > 0) {
        setSearch(search - 1);
      }      
    }
  }

  function handleChangePage(value) {
    setPage(value);
    loan
      .getLoanHistory({
        id: loanId,
        filter: filters,
        page: value,
        limit: limit
      })
      .then(res => {
        if (res.status === 200 && res.data) {
          const loans = res.data.map(item => ({
            key: res.data.indexOf(item)+1,
            number: res.data.indexOf(item)+1,
            amount: currencyNoPrefix(item.loan_amount),
            loanId: item.loan_code,
            status: (
              <Tag color={getLoanStatusClass(item.status)}>{item.status}</Tag>
            )
          }));
          setDataLoan(loans); 
          setTotal(res.meta.total_item);
        }
      });
  }

  function getLoanStatusClass(status) {
    switch (status) {
      case "approved":
        return "blue";
      case "paid_off":
        return "lime";
      case "disbursed":
        return "green";
      case "rejected":
        return "red";
      case "overdue":
        return "default";
      default:
        return "warning";
    }
  }

  useEffect(() => {
    let unmounted = false;
    loan
      .getLoanHistory({
        id: loanId,
        filter: filters,
        page: page,
        limit: limit
      })
      .then(res => {
        if (!unmounted && res.status === 200 && res.data) {
          const loans = res.data.map(item => ({
            key: res.data.indexOf(item)+1,
            number: res.data.indexOf(item)+1,
            amount: currencyNoPrefix(item.loan_amount),
            loanId: item.loan_code,
            status: (
              <Tag color={getLoanStatusClass(item.status)}>{item.status}</Tag>
            )
          }));
          setDataLoan(loans); 
          setTotal(res.meta.total_item);
        }
      });

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  dataLoan.unshift({
    key: 0,
    loanId: <Input type="text" name="loan_code" onChange={e => handleFilter(e)} />,
    amount: <Input type="text" name="loan_amount" onChange={e => handleFilter(e)} />,
    status: <FilterStatus />,
  });

  return (
    <React.Fragment>
      <h3>{t("employee:title.LoanHistory")}</h3>
      <Table columns={Columns} dataSource={dataLoan} pagination={false} />
      {total !== 0 && (
        <Pagination
          defaultPageSize={limit}  
          defaultCurrent={page} 
          current={page}
          total={total}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      )}
    </React.Fragment>
  )
}

export default LoanHistory;