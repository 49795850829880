const PARTNER_DASHBOARD = "koingaji/partner/dashboard";
const PARTNER_REGISTRATION = "koingaji/partner/registration";
const PARTNER_ACCOUNT = "koingaji/partner/account";
const PARTNER_UPDATE = "koingaji/partner/update/";
const PARTNER_BCA_TEMPLATE = "koingaji/partner/bca-template/download";
const PARTNER_DOWNLOAD_CURRENT = "koingaji/partner/loans/download-current";
const PARTNER_DOWNLOAD_INVOICE = "koingaji/partner/invoice/download";
const PARTNER_FEEDBACK = "koingaji/partner/feedback";
export const PATH_PARTNER = {
  PARTNER_ACCOUNT : PARTNER_ACCOUNT,
  PARTNER_DASHBOARD : PARTNER_DASHBOARD,
  PARTNER_REGISTRATION : PARTNER_REGISTRATION,
  PARTNER_UPDATE : PARTNER_UPDATE,
  PARTNER_BCA_TEMPLATE : PARTNER_BCA_TEMPLATE,
  PARTNER_DOWNLOAD_CURRENT : PARTNER_DOWNLOAD_CURRENT,
  PARTNER_DOWNLOAD_INVOICE : PARTNER_DOWNLOAD_INVOICE,
  PARTNER_FEEDBACK: PARTNER_FEEDBACK
};