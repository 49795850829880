
import { saveAs } from 'file-saver';

// const PROXY_URL = 'https://cors-proxy.htmldriven.com/?url=';

export function savePDF(url, filename) {
  var oReq = new XMLHttpRequest();
  oReq.open("GET", url, true); // Configure XMLHttpRequest
  oReq.responseType = "blob"; //  use the blob response type

  oReq.onload = function() {
    var file = new Blob([oReq.response], { 
        type: 'application/pdf' 
    });
    
    saveAs(file, filename);
  };

  oReq.send();
}

export function getFileNameFromUrl(url) {
  let strSplit = url.split('/');
  return strSplit.pop();
}