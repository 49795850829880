import React, { useState, useEffect } from "react";
import { Table, Card, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";
import history from "../../routers/history";
import discrepancy from "../../repository/Discrepancy";
import MenuList from "../../components/Menu/MenuList";
import { currencyNoPrefix } from "../../library/currencyFormatter";
import PATH_URL from "../../routers/path";

function DiscrepancyList(props) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const dataVendor = JSON.parse(window.localStorage.getItem("vnd"));

  const TableHead = [
    { 
      title: t("employee:label.no"), 
      dataIndex: 'number', 
      key: 'number' 
    },
    { 
      title: t("employee:label.salaryAdvDisbursed"), 
      dataIndex: 'salaryAdvance', 
      key: 'salaryAdvance' 
    },
    { 
      title: t("employee:label.salaryGiven"), 
      dataIndex: 'salaryGiven', 
      key: 'salaryGiven' 
    },
    { 
      title: t("employee:label.discrepancy"), 
      dataIndex: 'discrepancy', 
      key: 'discrepancy' 
    },
    { 
      title: t("employee:label.noEmployeeDiscrepancy"), 
      dataIndex: 'totalEmployee', 
      key: 'totalEmployee' 
    },
    { 
      title: t("employee:label.month"), 
      dataIndex: 'month', 
      key: 'month' 
    },
    // { 
    //   title: t("employee:label.status"), 
    //   dataIndex: 'status', 
    //   key: 'status' 
    // },
    { 
      title: "", 
      dataIndex: "action", 
      key: "action", 
      render: (text, record) => (
        record.key !== 0 ? (
          <div className="btn-table">
            <Button 
              size="small"
              type="default"
              className="default"
              onClick={
                () => history.push("/list/discrepancy/detail/" + record.month.replace(/\s/g, ''))
              }
            >
              {t("employee:button.details")}
            </Button>
          </div>
        ) : ""
      )
    }
  ];

  useEffect(() => {
    let unmounted = false;
    discrepancy
      .getList({
        id: dataVendor.vid
      })
      .then(res => {
        if (!unmounted && res.status === 200 && res.data) {
          const items = res.data.map(
            item => ({
              key: res.data.indexOf(item)+1,
              number: res.data.indexOf(item)+1,
              salaryAdvance: currencyNoPrefix(item.disbursed_loan),
              salaryGiven: currencyNoPrefix(item.salary_given),
              discrepancy: currencyNoPrefix(item.discrepancy_amount),
              totalEmployee: item.number_of_employee,
              month: item.period
            })            
          );
          setDataSource(items);
        }
      });

    return () => { 
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section>
      <div className="kwDiscrepancy">
        <h2>{t("employee:title.discrepancy")}</h2>
        <MenuList
          activeKey="discrepancy"
        />
        <Card>
          <h3>{dataVendor.name}</h3>
          <div className="kwDiscrepancy__top-button">
            <Button 
              type="default"
              onClick={ () => history.push(PATH_URL.DISCREPANCY_UPLOAD) }
            >
              {t("employee:label.uploadDiscrepancy")}
            </Button>
          </div>  
          <Table
            columns={TableHead}
            dataSource={dataSource} 
            pagination={false}
          />     
        </Card>
      </div>      
    </Section>
  );
};

export default DiscrepancyList;
