let currencyFormatter = require("currency-formatter");

export const currencyNoPrefix = (number) => {
  return currencyFormatter.format(number, {
    symbol: "",
    decimal: ',',
    thousand: ",",
    precision: 0,
    //format: "%v %s" // %s is the symbol and %v is the value
  });
};

export const currencyPrefixRp = (number) => {
  return currencyFormatter.format(number, {
    symbol: "Rp ",
    decimal: ',',
    thousand: ",",
    precision: 0,
    //format: "%v %s" // %s is the symbol and %v is the value
  });
};