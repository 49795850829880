import React from 'react';
import EmployeeDetailContent from '../../containers/EmployeeDetail';

function EmployeeDetail(props) {
    return (
        <React.Fragment>
            <EmployeeDetailContent match={props.match} />
        </React.Fragment>
    );
}

export default EmployeeDetail;