import styled from "styled-components";

export const Section = styled.div`
  .modal-wrapper {    
    font-family: 'Roboto', 'Helvetica';
    font-style: normal;
    padding: 20px
  }
  .modal-img {
    text-align: center;
    margin: 10px;
  }
  h3 {
    text-align: center
  }
`;
