import styled from "styled-components";

export const Section = styled.div`
	.kwSetting {
    padding: 0 1%;
    &__top-button {
      text-align: right;
      padding: 0px 20px;
      top: -12px;
      position: relative;
      button {
        margin: 0 10px;
      }
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      color: #2D2D2D;
      .anticon-left {
        position: relative;
        top: -10px;
      }
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
      color: #000000;
    }
    .ant-card {
      margin: 0 1%;
    }
    .ant-form {
      .ant-col {
        padding: 0 15px;
      }
      .input-label {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-transform: uppercase;
        color: #000000
      }
    }
    .ant-switch {
      border-radius: 20px!important;
      margin: 10px;
      height: 30px;
      width: 100%;
    }
    .ant-switch::after {
      top: 0;
      width: 28px;
      height: 28px;
      margin-left: 0;
    }
    .switch-text {
      text-transform: uppercase;
      font-size: 11px;
      margin-top: 8px
    }
    .error-validation {
      color: #ff4d4f;
      margin: 0;
      padding: 0;
    }
  }
`;