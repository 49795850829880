import React from "react";
import { Card, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";

function PromoContent(props) {
  const { t } = useTranslation(); 
  const { Meta } = Card;

  const showPromotions = false;
  const images = [
    {
      name: "Promo 1",
      file: "https://plchldr.co/i/500x250"
    },
    {
      name: "Promo 2",
      file: "https://plchldr.co/i/500x250"
    },    
    {
      name: "Promo 3",
      file: "https://plchldr.co/i/500x250"
    }
  ]

  const referUrl = 'https://forms.gle/YreTpYLa9C5PBszj9';

  return (
    <Section>
      <div className="kwPromo">
        <h2>{t("promo:title.promo")}</h2>
        <Card>
          { showPromotions ? 
          <>
          <h3>{t("promo:title.promotions")}</h3>
            <Row justify="space-between">
            
            { images && images.map(image => (
              <Col span={8}>
                <Card
                    style={{ width: 350 }}
                  cover={
                    <img alt={image.name} src={image.file} />
                  }
                >
                  <Meta
                    title={image.name}
                  />
                </Card>              
              </Col>
            ))} 
            </Row>
          </>
          : null
          }

          <h3 className="mt-1">{t("promo:title.getIncentives")}</h3>
          <Row>
            <Col span={24}>
              <h4 className="mx-1">
                <a
                  href={referUrl} target="_blank"
                  rel="noopener noreferrer"
                >{referUrl}
                </a>
              </h4>
            </Col>
          </Row>          
        </Card>
      </div>
    </Section>
  );
};

export default PromoContent;
