import { 
  SettingFilled,
  ProfileOutlined,
  // AppstoreOutlined,
  DollarCircleOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import PATH_URL from "../../routers/path";
export const MenuItems = [
  // {
  //   key: 'dashboard',
  //   label: "common:dashboard.dashboard",
  //   path: PATH_URL.HOME,
  //   icon: AppstoreOutlined
  // },
  {
    key: 'list',
    label: "common:dashboard.list",
    path: PATH_URL.EMPLOYEE_LIST,
    icon: ProfileOutlined
  },
  // {
  //   key: 'fee',
  //   label: "common:dashboard.successFee",
  //   path: "/success-fee",
  //   icon: DollarCircleOutlined
  // },
  {
    key: 'setting',
    label: "common:dashboard.setting",
    path: PATH_URL.SETTING,
    icon: SettingFilled
  },
  {
    key: 'help',
    label: "common:dashboard.help",
    path: PATH_URL.HELP,
    icon: QuestionCircleOutlined
  },
  {
    key: 'promotions & incentives',
    label: "common:dashboard.promo",
    path: PATH_URL.PROMO,
    icon: DollarCircleOutlined
  }
];