import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Input, message, Pagination, Spin, Select, Divider, Alert } from 'antd';
import history from '../../routers/history';
import employee from '../../repository/Employee';
import { currencyNoPrefix } from '../../library/currencyFormatter';
import { dateLocaleStringFormat } from '../../library/dateTimeFormatter';
import { snakeCaseToCapital } from '../../library/stringFormatter';
import { saveAs } from 'file-saver';
import { getFileNameFromUrl } from '../../library/fileSaverHelper';
import FreezeAllModal from '../../components/Modal/FreezeAllModal';
import { pageSizePagination } from '../../library/componentHelper';

const { Option } = Select;

const EmployeeListTable = ({ title, t, updateData, handleModal }) => {
    const lang = window.localStorage.getItem('language') || 'en';
    const dataVendor = JSON.parse(window.localStorage.getItem('vnd'));
    const limitPageSize = localStorage.getItem('limitPageSize') || 10;

    const [dataEmployee, setDataEmployee] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [filters, setFilters] = useState({});
    const [rowId, setRowId] = useState('');
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState('');
    const [search, setSearch] = useState(0);
    const [limit, setLimit] = useState(limitPageSize);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loanOption, setLoanOption] = useState('');
    const [disabledApply, setDisabledApply] = useState(true);
    const [isModalNotification, setIsModalNotification] = useState(false);
    const [option, setOption] = useState('');

    const onShowSizeChange = (current, pageSize) => {
        setLimit(pageSize);
        localStorage.setItem('limitPageSize', pageSize);
    };

    // multiple checkbox
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setLoanOption('');
            setDisabledApply(true);
        },
        renderCell(checked, record, index, node) {
            // hidden checkbox
            if (index <= 0) return '';
            return node;
        },
        getCheckboxProps: (record) => ({
            // example not to be checked
            disabled: record.key === 0,
            key: record.key,
            employmentType: record.employmentType,
        }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    const handleFreezeOption = (option) => {
        setIsModalNotification(true);
        setOption(option);
    };

    const applyLoanOption = (vendor_id) => {
        let params = { employee_ids: selectedRowKeys, loan_access: loanOption }; // get all checked

        setLoading(true);
        employee
            .putLoanOption({
                vendor_id: vendor_id,
                params: params,
            })
            .then((res) => {
                if (res.status === 200) {
                    setSelectedRowKeys([]);
                } else {
                    message.error(res.errors[0].userMessage);
                }
                setLoading(false);
            });
    };

    function handleChangeLoanOptions(value) {
        let apply = value.length > 0 ? false : true;
        setDisabledApply(apply);
        setLoanOption(value);
    }

    function handleFilter(dataIndex, { target }) {
        let term = target.value;
        let terms = filters;
        if (term.length > 1) {
            terms[target.name] = '&' + target.name + '=' + term;
            setFilters(terms);
            setPage(0);
            setSearch(search + 1);
        }

        if (term.length === 0) {
            let key = target.name;
            delete terms[key];
            setFilters(terms);
            if (search > 0) {
                setSearch(search - 1);
            }
        }
    }

    function handleOptionFilter(filterName, value) {
      let terms = filters;
      if (typeof value === 'string' && value.length > 1) {
        terms[filterName] = '&' + filterName + '=' + value;
        setFilters(terms);
        setPage(0);
        setSearch(search + 1);
      }

      if (typeof value === 'string' && value.length === 0) {
        delete terms[filterName];
        setFilters(terms);
        if (search > 0) {
            setSearch(search - 1);
        }
      }
    }

    function getEmployeeTemplate() {
        employee.getEmployeeTemplate({}).then((res) => {
            if (res.status === 200 && res.data) {
                saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
            } else {
                message.error(res.message[lang]);
            }
        });
    }

    function getDownloadEmployeeList() {
        employee.getDownloadEmployeeList({ id: dataVendor.vid }).then((res) => {
            if (res.status === 200 && res.data) {
                saveAs(res.data.url_download, getFileNameFromUrl(res.data.url_download));
            } else {
                message.error(res.message[lang]);
            }
        });
    }

    const handleViewEmployee = (id) => {
        history.push('/list/employee/detail/' + id);
    };

    // function to open message on success delete employee
    // displays replace effect (previously stacked) if delete multiple item
    const openMessage = (name) => {
        message.success({
            content: `${t('employee:text.successDelete')}`,
            key: 'replace',
            duration: 1,
        });
        setTimeout(() => {
            message.success({
                content: `${t('employee:text.successDelete')}: ${name}`,
                key: 'replace',
                duration: 3,
            });
        }, 0);
    };

    const handleDelete = (id, name) => {
        setRowId(id);
        setDeleting(true);
        employee.deleteEmployee({ id: id }).then((res) => {
            if (res.status === 200) {
                setDeleted(id);
                openMessage(name);
            } else {
                message.error(res.errors[0].moreInfo);
            }
            setDeleting(false);
        });
    };

    const handleOkDownloadTemplate = () => {
        getEmployeeTemplate();
    };

    const handleDownloadEmployee = () => {
        getDownloadEmployeeList();
    };

    function handleChangePage(value) {
        setPage(value - 1);
        employee
            .getEmployeeList({
                limit: limit,
                page: value - 1,
                filter: filters,
            })
            .then((res) => {
                if (res.status === 200 && res.data) {
                    let num = (value - 1) * limit;
                    let employees = res.data.map((item) => ({
                        key: item.employee_id,
                        number: res.data.indexOf(item) + num + 1,
                        accNumber: item.account_number,
                        salary: currencyNoPrefix(item.salary),
                        employeeNumber: item.employee_number,
                        name: item.employee_name,
                        department: item.department,
                        bankName: item.bank_name,
                        ktp: item.nik,
                        dob: dateLocaleStringFormat(item.dob),
                        phone: item.phone_number,
                        since: dateLocaleStringFormat(item.employeed_since),
                        employmentType: snakeCaseToCapital(item.employment_type),
                        position: item.position,
                        endOfContract:
                            item.employment_type === 'contract' ? dateLocaleStringFormat(item.end_of_contract) : '-',
                        loanOption: item.loan_access ? snakeCaseToCapital(item.loan_access) : '-',
                        employeeStatus: item.status ? item.status : '-',
                    }));

                    setDataEmployee(employees);
                    setTotal(res.meta.total_item);
                } else {
                    let errMsg = `${res.status}: Failed to get data. `;
                    if (res.errors.length > 0 && 'moreInfo' in res.errors[0]) {
                        errMsg += res.errors[0].moreInfo;
                    } else {
                        errMsg += res.message.en;
                    }
                    message.error(errMsg);
                }
            });
    }

    const employeeStatusOptions = [
      { value: "activated", label: "Activated" },
      { value: "pending", label: "Pending" },
      { value: "unreachable", label: "Unreachable" },
      { value: "resigned", label: "Resigned" },
      { value: "frozen", label: "Frozen" },
      { value: "rejected", label: "Rejected" },
      { value: "deactivated", label: "Deactivated" },
    ]

    const EmployeeHead = [
        {
            title: t('employee:label.no'),
            dataIndex: 'number',
            key: 'number',
            width: 70,
            fixed: 'left',
        },
        {
            title: t('employee:label.employeeName'),
            dataIndex: 'name',
            key: 'name',
            width: 200,
            fixed: 'left',
        },
        {
            title: t('employee:label.salary'),
            dataIndex: 'salary',
            key: 'salary',
            width: 150,
        },
        {
            title: t('employee:label.employeeNumber'),
            dataIndex: 'employeeNumber',
            key: 'employeeNumber',
            width: 180,
        },
        {
            title: t('employee:label.employeeStatus'),
            dataIndex: 'employeeStatus',
            key: 'employeeStatus',
            width: 180,
        },
        {
            title: t('employee:label.accountNumber'),
            dataIndex: 'accNumber',
            key: 'accNumber',
            width: 150,
        },
        {
            title: t('employee:label.loanOption'),
            dataIndex: 'loanOption',
            key: 'loanOption',
            width: 150,
        },
        {
            title: t('employee:label.department'),
            dataIndex: 'department',
            key: 'department',
            width: 150,
        },
        {
            title: t('employee:label.bankName'),
            dataIndex: 'bankName',
            key: 'bankName',
            width: 120,
        },
        {
            title: t('employee:label.ktpNumber'),
            dataIndex: 'ktp',
            key: 'ktp',
            width: 175,
        },
        {
            title: t('employee:label.dob'),
            dataIndex: 'dob',
            key: 'dob',
            width: 150,
        },
        {
            title: t('employee:label.phoneNumber'),
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        {
            title: t('employee:label.employedSince'),
            dataIndex: 'since',
            key: 'since',
            width: 150,
        },
        {
            title: t('employee:label.employmentType'),
            dataIndex: 'employmentType',
            key: 'employmentType',
            width: 150,
        },
        {
            title: t('employee:label.position'),
            dataIndex: 'position',
            key: 'position',
            width: 150,
        },
        {
            title: t('employee:label.endOfContract'),
            dataIndex: 'endOfContract',
            key: 'endOfContract',
            width: 150,
        },
        {
            title: t('employee:label.action'),
            dataIndex: 'action',
            key: 'action',
            width: 100,
            fixed: 'right',
            render: (text, record) =>
                record.key !== 0 ? (
                    <div className="btn-table">
                        {/* <Button size="small" className="default" onClick={() => handleViewEmployee(record.key)}>
                            {t('employee:button.update')}
                        </Button> */}

                        <Button
                            size="small"
                            className="danger"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(record.key, record.name);
                            }}
                        >
                            {t('employee:button.remove')}
                        </Button>

                        {/* <Button size="small" className="default" onClick={() => handleViewEmployee(record.key)}>
                            {t('employee:button.details')}
                        </Button> */}
                    </div>
                ) : (
                    ''
                ),
        },
    ]

    const isComponentMounted = useRef(false);

    useEffect(() => {
        isComponentMounted.current = true;
        return () => {
        isComponentMounted.current = false;
        };
    }, []);

    useEffect(() => {
        employee
            .getEmployeeList({
                limit: limit,
                page: page,
                filter: filters,
            })
            .then((res) => {
                if (isComponentMounted.current && res.status === 200 && res.data === null) {
                    setDataEmployee([]);
                }

                if (isComponentMounted.current && res.status === 200 && res.data) {
                    let employees = res.data.map((item) => ({
                        key: item.employee_id,
                        number: res.data.indexOf(item) + 1 + page * limit,
                        accNumber: item.account_number,
                        salary: currencyNoPrefix(item.salary),
                        employeeNumber: item.employee_number,
                        name: item.employee_name,
                        department: item.department,
                        bankName: item.bank_name,
                        ktp: item.nik,
                        dob: dateLocaleStringFormat(item.dob),
                        phone: item.phone_number,
                        since: dateLocaleStringFormat(item.employeed_since),
                        employmentType: snakeCaseToCapital(item.employment_type),
                        position: item.position,
                        endOfContract:
                            item.employment_type.toLowerCase() === 'contract'
                                ? dateLocaleStringFormat(item.end_of_contract)
                                : '-',
                        loanOption: item.loan_access ? snakeCaseToCapital(item.loan_access) : '-',
                        employeeStatus: item.status ? snakeCaseToCapital(item.status) : '-',
                    }));
                    setDataEmployee(employees);
                    setTotal(res.meta.total_item);
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, updateData, search, selectedRowKeys, limit]);

    dataEmployee.unshift({
        key: 0,
        number: '',
        accNumber: <Input type="text" name="account_number" onChange={(e) => handleFilter('accNumber', e)} />,
        salary: '',
        employeeNumber: (
            <Input type="text" name="employee_number" onChange={(e) => handleFilter('employeeNumber', e)} />
        ),
        employeeStatus: (
          <Select
            name="employee_status"
            allowClear
            style={{ width: 120 }}
            onChange={(e) => handleOptionFilter("employee_status", e)}
            options={employeeStatusOptions}
          />
        ),
        name: <Input type="text" name="employee_name" onChange={(e) => handleFilter('name', e)} />,
        department: <Input type="text" name="department" onChange={(e) => handleFilter('department', e)} />,
        bankName: <Input type="text" name="bank_name" onChange={(e) => handleFilter('bankName', e)} />,
        ktp: <Input type="text" name="nik" onChange={(e) => handleFilter('ktp', e)} />,
        dob: '',
        phone: <Input type="text" name="phone_number" onChange={(e) => handleFilter('phone', e)} />,
        since: '',
        employmentType: <Input type="text" name="job_status" onChange={(e) => handleFilter('employmentType', e)} />,
        position: <Input type="text" name="position" onChange={(e) => handleFilter('position', e)} />,
        endOfContract: '',
        loanOption: '',
    });

    return (
        <React.Fragment>
            <h3>{title}</h3>
            <div className="kwEmployee__top-button">
                <div hidden={!hasSelected}>
                    <Alert message={t('employee:text.potentialLoss')} type="info" style={{ float: 'left' }} showIcon />
                    <Select
                        value={loanOption}
                        onChange={handleChangeLoanOptions}
                        style={{ width: 150, textAlign: 'left' }}
                    >
                        <Option value="" disabled>
                            Loan Option
                        </Option>
                        <Option value="all">All</Option>
                        <Option value="salary_advance">Salary Advance</Option>
                        <Option value="installment">Installment</Option>
                    </Select>

                    <Button disabled={disabledApply} onClick={(e) => applyLoanOption(dataVendor.vid)}>
                        {t('employee:button.apply')}
                    </Button>
                    <Divider type="vertical" />
                    <Button type="danger" onClick={() => handleFreezeOption('freeze')}>
                        {t('employee:button.freezeAll')}
                    </Button>
                    <Button type="primary" onClick={() => handleFreezeOption('unfreeze')}>
                        {t('employee:button.unfreezeAll')}
                    </Button>
                </div>

                <div hidden={hasSelected}>
                    <Button type="default" onClick={handleDownloadEmployee}>
                        {t('employee:button.downloadEmployee')}
                    </Button>

                    <Button type="default" onClick={handleOkDownloadTemplate}>
                        {t('employee:button.downloadTemplate')}
                    </Button>

                    <Button type="default" onClick={handleModal}>
                        {t('employee:button.bulkUpload')}
                    </Button>

                    <Button type="default" onClick={() => history.push('/list/employee/new')}>
                        {t('employee:button.addEmployee')}
                    </Button>
                </div>
            </div>

            <Table
                rowSelection={rowSelection}
                columns={EmployeeHead}
                dataSource={dataEmployee}
                pagination={false}
                scroll={{ x: 'calc(700px + 50%)' }}
                rowClassName={(record, index) =>
                    record.key === rowId && record.key > 0 && deleting
                        ? 'row-delete pointer'
                        : index !== 0
                        ? 'pointer'
                        : ''
                }
                onRow={(record, rowIndex) => {
                    return {
                        onClick: rowIndex > 0 ? (event) => handleViewEmployee(record.key) : '',
                    };
                }}
                loading={{
                    indicator: (
                        <div>
                            <Spin />
                        </div>
                    ),
                    spinning: loading,
                }}
            />

            <div className="pagination">
                {total && (
                    <Pagination
                        showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                        defaultPageSize={limit}
                        defaultCurrent={page + 1}
                        current={page + 1}
                        total={total}
                        onChange={handleChangePage}
                        showSizeChanger={true}
                        onShowSizeChange={onShowSizeChange}
                        pageSizeOptions={pageSizePagination(total)}
                    />
                )}
            </div>

            <FreezeAllModal
                visible={isModalNotification}
                lang={t}
                vendor_id={dataVendor.vid}
                setIsModalNotification={setIsModalNotification}
                setLoading={setLoading}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                option={option}
            />
        </React.Fragment>
    );
};

export default EmployeeListTable;
