import React from "react";
import { Card, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "./style";

function SuccessFeeDetails(props) {
  const { t } = useTranslation(); 

  const FeeHead = [
    { title: t("fee:label.no"), dataIndex: 'number', key: 'number' },
    { title: t("fee:label.accountNumber"), dataIndex: 'accNumber', key: 'accNumber' },
    { title: t("fee:label.employeeNumber"), dataIndex: 'number', key: 'number' },
    { title: t("fee:label.employeeName"), dataIndex: 'name', key: 'name' },
    { title: t("fee:label.department"), dataIndex: 'department', key: 'department' },
    { title: t("fee:label.transactionDate"), dataIndex: 'date', key: 'date' },
    { title: t("fee:label.salary"), dataIndex: 'salary', key: 'salary' },
    { title: t("fee:label.loanAmount"), dataIndex: 'amount', key: 'amount' },
    { title: t("fee:label.successFee"), dataIndex: 'fee', key: 'fee' },
    { title: t("fee:label.status"), dataIndex: 'status', key: 'status' }
  ];

  return (
    <Section>
      <div className="kwEmployee">
        <h2>{t("fee:title.successFee")}</h2>
        <Card>
          <h3>Company Name</h3>
          <Table columns={FeeHead} />
        </Card>
      </div>
    </Section>
  );
};

export default SuccessFeeDetails;
