import React from 'react';
import PromoContent from '../../containers/Promo';

function Promo(props) {
    return (
        <React.Fragment>
            <PromoContent/>
        </React.Fragment>
    );
}

export default Promo;