import React from 'react';
import DiscrepancyDetailContent from '../../containers/DiscrepancyDetail';

function DiscrepancyDetail(props) {
    return (
        <React.Fragment>
            <DiscrepancyDetailContent match={props.match} />
        </React.Fragment>
    );
}

export default DiscrepancyDetail;