const EMPLOYEE_LIST = 'koingaji/partner/employee';
const EMPLOYEE_UPDATE = 'koingaji/partner/employee/update/';
const EMPLOYEE_DELETE = 'koingaji/partner/employee/delete/';
const EMPLOYEE_DETAIL = 'koingaji/partner/employee/';
const EMPLOYEE_BULK_UPLOAD = 'koingaji/partner/employee/upload';
const EMPLOYEE_CREATE = 'koingaji/partner/employee/create';
const EMPLOYEE_FREEZE = 'koingaji/partner/employee/freeze/';
const EMPLOYEE_STATUS_UPDATE = 'koingaji/partner/employee/status/';
const EMPLOYEE_RESIGNED = 'koingaji/partner/employee/resign-request/';
const EMPLOYEE_OUTSTANDING = 'koingaji/partner/employee/outstanding/';
// const EMPLOYEE_STATUS_UPDATE = "koingaji/partner/employee/status/";
const EMPLOYEE_TEMPLATE = 'koingaji/employee-template';
const EMPLOYEE_FREEZE_ALL = 'koingaji/partner/employee/';
const EMPLOYEE_LOAN_OPTION_ALL = 'koingaji/partner/employee/';
const EMPLOYEE_BULK_UPLOAD_V2 = 'koingaji/partner/employee/upload-v2';

export const PATH_EMPLOYEE = {
    EMPLOYEE_BULK_UPLOAD: EMPLOYEE_BULK_UPLOAD,
    EMPLOYEE_CREATE: EMPLOYEE_CREATE,
    EMPLOYEE_DELETE: EMPLOYEE_DELETE,
    EMPLOYEE_DETAIL: EMPLOYEE_DETAIL,
    EMPLOYEE_FREEZE: EMPLOYEE_FREEZE,
    EMPLOYEE_LIST: EMPLOYEE_LIST,
    EMPLOYEE_UPDATE: EMPLOYEE_UPDATE,
    EMPLOYEE_STATUS_UPDATE: EMPLOYEE_STATUS_UPDATE,
    EMPLOYEE_RESIGNED: EMPLOYEE_RESIGNED,
    EMPLOYEE_OUTSTANDING: EMPLOYEE_OUTSTANDING,
    EMPLOYEE_TEMPLATE: EMPLOYEE_TEMPLATE,
    EMPLOYEE_FREEZE_ALL: EMPLOYEE_FREEZE_ALL,
    EMPLOYEE_LOAN_OPTION_ALL: EMPLOYEE_LOAN_OPTION_ALL,
    EMPLOYEE_BULK_UPLOAD_V2: EMPLOYEE_BULK_UPLOAD_V2
};
