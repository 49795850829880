import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Form, Input, Button, Checkbox } from "antd";
import { Section } from "./style";
import { useTranslation } from "react-i18next";
import { useRootContext } from "../../hoc/RootContext";
import history from "../../routers/history";
import config from "../../config";
// import ClevertapReact from "../../library/clevertap";
import clevertap from 'clevertap-web-sdk';
import circleImg from "../../assets/images/circle.svg";
import SuccessLoginModal from "../../components/Modal/SuccessLoginModal";
import PATH_URL from "../../routers/path";

function LoginForm(props) {
  const { t } = useTranslation();
  const { authenticationUser, authError, isAuthenticated, partner } = useRootContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const lang = window.localStorage.getItem('language') || 'en';

  const handleUsernameChange = ({ target }) => {
    setUsername(target.value);
  }

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  }

  const actionChangeLoadingShow = value => {
    setShowLoading(value);
  }

  const actionChangeAlert = value => {
    setShowAlert(value);
  }

  const actionShowModal = value => {
    setShow(value);
  }

  const handleLogin = () => {
    setShowAlert(false);
    let body = {
      email: username,
      password: password
    };
    authenticationUser(
      body,
      actionChangeAlert,
      actionChangeLoadingShow,
      actionShowModal
    );
  }

  if (isAuthenticated) {
    let loginData = {
      "Partner Name" : partner?.company_name || "",
      "Email Address": username,
      "Phone Number" : partner?.pic_phone_number || "",
      "Account Type": "partner",
      "Source": "Koingaji Partner",
      "Method": "Password",
      "Login Location": "-",
      "Date": new Date()
    };
    clevertap.onUserLogin.push({
      "Site": {
        "Name": partner?.company_name || "",
        "Email": username,
        "Account Type": "partner"
      },
      "ProfileData": {
        "Partner Name": partner?.company_name || "",
        "Email": username,
        "AccountType": "partner"
      }
    });
    clevertap.event.push("Login", loginData);
    setTimeout(() => {
      history.push(PATH_URL.EMPLOYEE_LIST);
    }, 1000);
  }

  return (
    <Section>
      <div className="kwLoginWrapper">
        <Card>
          <div className="kwLoginWrapper__top-content">
            <h3 className="page-title">{t("common:title.loginPage")}</h3>
            <Form autoComplete="off">
              <Form.Item>
                <span className="input-label">{t("common:label.username")}</span> 
                <Input
                  size="large"
                  type="text"
                  id="username"
                  value={username}
                  placeholder={t("common:label.username")}
                  onChange={handleUsernameChange}
                />
              </Form.Item>
              <Form.Item>
                <span className="input-label">{t("common:label.password")}</span> 
                <Input
                  size="large"
                  type="password"
                  id="password"
                  value={password}
                  placeholder={t("common:label.password")}
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              <Form.Item>
                <div className="kwCheckAgreement">
                  <Checkbox>
                  </Checkbox>
                  <p>{t("common:label.rememberMe")}</p>
                </div>
              </Form.Item>
              <Button
                type="primary"
                size="large"
                className="w-100"
                onClick={handleLogin}
                loading={showLoading}
              >
                {t("common:button.login")}
              </Button>
              { showAlert && authError && (
                <p className="kw-error-message">{authError[lang]}</p>
              )}
              <div className="text-center">
                <a
                  href={config.api.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link"
                >
                  {t("common:label.forgotPassword")}
                </a>
              </div>
            </Form>
          </div>
        </Card>
        <Card>
          <Link to="/partnership">
            <div className="kwLoginWrapper__bottom-content">
              <figure className="bottom-img">
                <img src={circleImg} alt="" />
              </figure>
              <div className="bottom-text">
                <h5>{t("common:text.interestTo")}</h5>
                <span>{t("common:text.tapHere")}</span>
              </div>
            </div>
          </Link>
        </Card>
      </div>

      <SuccessLoginModal 
        visible={show}
        width={400}
        lang={t}
        onClose={null}
      />

    </Section>
  );
};

export default LoginForm;
