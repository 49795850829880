import React, { useState } from "react";
import { Modal, Button, Space, Spin, Alert, Typography, Rate, Input, message} from "antd";
import { FileOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone";
import { Section } from "./style";
import { useRootContext } from "../../../hoc/RootContext";
import fileImg from "../../../assets/images/file-plus.svg";
import partner from "../../../repository/Partner";
import { saveAs } from "file-saver";

const AddEmployeeModal = ({
    visible,
    width,
    lang,
    handleUpdate,
    onClose,
    setIsNotification
  }) => {
    const { Text } = Typography;
    const { TextArea } = Input;
    const language = window.localStorage.getItem("language") || "en";
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [uploadingMsg, setUploadingMsg] = useState("");
    const [feedback, setFeedback] = useState(false);
    const [feedbackRating, setFeedbackRating] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [uploaded, setUploaded] = useState(false);
    const [btnModalText, setBtnModalText] = useState(lang("employee:button.uploadList"));
    const [uploadData, setUploadData] = useState(null);
    const { 
      bulkUploadEmployee,
      bulkEmployeeError,
      metaUpload,
      uploadFilteredEmployee,
      // sendFeedback
    } = useRootContext();

    const handleFiles = (files) => {      
      setAlert(false);
      setFiles(files);
    }

    const handleCancel = () => {
      setFiles([]);
      setAlert(false);
      onClose();
      setIsNotification(true);
      setBtnModalText(lang("employee:button.uploadList"));
      setUploaded(false);
      setUploadData(null)
    }

    const handleReset = () => {
      setFiles([]);
      setAlert(false);
    }

    function handleFeedbackRating(value) {
      setFeedbackRating(value);
    }

    function handleFeedbackMessage({ target }) {
      setFeedbackMessage(target.value);
    }

    const actionChangeLoadingShow = value => {
      setUploading(value);
    }

    const actionChangeLoadingMessage = value => {
      setUploadingMsg(value);
    }

    const actionChangeAlert = value => {
      setAlert(value);
    }

    const actionUpdateData = () => {
      handleUpdate();
      setFiles([]);
    }

    const actionCloseModal = () => {
      setAlert(false);
      onClose();
    }
    
    const actionChangeUploadedStatus = value => {
      setUploaded(value)
    }

    const actionChangeButtonModalText = () => {
      setBtnModalText(lang("employee:button.done"))
    }

    const handleFailedUpload = () => {
      saveAs(uploadData?.false_file_url);
    }

    const actionSetUploadData = value => {
      setUploadData(value);
    }

    const actionDeleteUploadData = () => {
      setUploadData(null);
    }

    const handleUpload = () => {
      let vnd = JSON.parse(window.localStorage.getItem("vnd"));
      let feedbackAlready = localStorage.getItem("feedback_already");
      if (uploaded){
        if(feedbackAlready){
          actionCloseModal();
          message.success(lang("employee:text.successDataUpdate"));
          setTimeout(function(){
            window.location.reload();
          }, 500); 
        }else{
          if(feedback){
            let data = {
              rating: feedbackRating,
              message: feedbackMessage
            }
            partner
            .postFeedback({ params: data })
            .then(res => {
              if (res.status === 200) {
                  actionCloseModal();
                  localStorage.setItem("feedback_already", true);
                  message.success(lang("employee:text.successDataUpdate"));
                  setTimeout(function(){
                    window.location.reload();
                  }, 500); 
                }else{
                  message.error(res.message[language]);
                }
              });
          }else{
            setFeedback(true);
            setBtnModalText(lang("employee:button.send"));
          }    
        }
      }else{
        var uploadFiles = new Promise((resolve, reject) => {
            files.forEach((file, index, array) => {
              handleBukEmployee(file, vnd.vid, vnd.email);
                if (index === array.length -1) resolve();
            });
        });
        
        uploadFiles.then(() => {
            setUploaded(true);
        });
      }

    }

    function handleBukEmployee(targetFile, id, email) {
      // let fileReader = new FileReader();
      let bodyForm = new FormData();
      bodyForm.append('file', targetFile);
      bodyForm.append('path', `koingaji/vendor-${id}/employee/`);
      bodyForm.append('filename', `upload_${Date.now()}`);

      bulkUploadEmployee(
        { params : bodyForm },
        id,
        actionChangeAlert,
        actionChangeLoadingShow,
        actionChangeLoadingMessage,
        actionUpdateData,
        actionCloseModal,
        actionChangeUploadedStatus,
        actionChangeButtonModalText,
        actionSetUploadData,
        actionDeleteUploadData
      );
      // fileReader.onloadend = () => {
      //   let params = {
      //     base64: [fileReader.result.replace('application/vnd.ms-excel', 'application/xls')],
      //     email: email
      //   };
      //   bulkUploadEmployee(
      //     { params : params },
      //     id,
      //     actionChangeAlert,
      //     actionChangeLoadingShow,
      //     actionChangeLoadingMessage,
      //     actionUpdateData,
      //     actionCloseModal
      //   );
        
      // };
      // fileReader.readAsDataURL(targetFile);
    }

    function continueUploadEmployee() {
      let vnd = JSON.parse(window.localStorage.getItem("vnd"));
      setUploading(true);
      
      uploadFilteredEmployee(
        {
          vendor_id: vnd.vid,
          correct_rows: uploadData.correct_rows
        },
        actionChangeAlert,
        actionChangeLoadingShow,
        actionChangeLoadingMessage,
        actionUpdateData,
        actionCloseModal,
        actionChangeUploadedStatus,
        actionChangeButtonModalText,
        actionDeleteUploadData
      );
    }

    return (
      <Modal
        visible={visible}
        width={width}
        onCancel={onClose}
        closable={false}
        title={lang("employee:label.uploadEmployee")}
        maskClosable={false}
        footer={typeof(metaUpload) === 'object' && alert ? [
          <Button 
            key="upload"
            type="primary"
            disabled={uploading}
            onClick={handleUpload}
          >
            {btnModalText}
          </Button>
        ] : uploadData ? [
          <Button
            key="cancel"
            disabled={uploading}
            onClick={handleCancel}
          >
            {lang("employee:button.cancel")}
          </Button>,
          <Button
            key="downloadFailed"
            disabled={uploading}
            danger
            onClick={handleFailedUpload}
          >
            {lang("employee:button.downloadFailedData")}
          </Button>,
          <Button 
            key="upload"
            type="primary"
            disabled={uploading || !uploadData?.correct_rows}
            onClick={continueUploadEmployee}
          >
            {lang("employee:button.uploadFilteredData")}
          </Button>
        ] : [
          <Button
            key="cancel"
            disabled={uploading}
            onClick={handleCancel}
          >
            {lang("employee:button.cancel")}
          </Button>,
          <Button 
            key="upload"
            type="primary"
            disabled={uploading}
            onClick={handleUpload}
          >
            {btnModalText}
          </Button>
        ]}
      >
        <Section>
          <div className="modal-wrapper">
            <section className="dropzone text-center">
                {files.length > 0 ? (
                  <div className="dropzone-preview">
                    {files.map(file => (
                      <p key={file.name}><FileOutlined /> {file.name}</p>
                    ))}
                    {uploading && (
                      <div>
                        <Space>
                          <Spin size="large" />
                        </Space>
                        <span className="text-center text-primary ml-2">  {uploadingMsg}</span>
                      </div>
                    )}
                    {bulkEmployeeError && alert && !uploading && (
                      <div className="text-center">
                        <p className="error-message">
                          {bulkEmployeeError}
                        </p>
                        <Button type="default" onClick={handleReset}>
                          Reset
                        </Button>
                      </div>
                    )}
                    {uploadData && alert && !uploading && (
                      <div className="text-center">
                        <p className="error-message">
                          {uploadData?.correct_rows ? lang("employee:text.failedDataFound") : lang("employee:text.allFailedDataFound")}
                        </p>
                      </div>
                    )}
                  </div>
                ):(
                  <div>
                  {typeof(metaUpload) === 'object' && alert ? (
                    <div className="text-left text-success-update-employe p-4">
                      {feedback ? (
                        <div>
                          <h5>{lang("employee:text.successUpdateUpload")}</h5>
                          <p className="mb-1">{lang("employee:text.textRating")}</p>
                          <Rate className="mb-3" onChange={handleFeedbackRating} />
                        
                          <p>{lang("employee:text.textFeedback")}</p>
                          <TextArea onChange={handleFeedbackMessage} rows={4} />
                        </div>
                      ): (
                        <div>
                          { metaUpload.inserted_rows === 0 && metaUpload.updated_rows === 0 && metaUpload.deleted_rows === 0 ? (
                            <h5>{lang("employee:text.failedUploaded")}</h5>
                          ) : (
                            <>
                              <h5>{lang("employee:text.successUploaded")}</h5>
                              <Text>
                                <div dangerouslySetInnerHTML={{
                                  __html: lang("employee:text.textSuccessUploadEmploye")
                                }}></div>
                              </Text>
                              <p className="success-message mt-3">
                                <b>Success!</b>
                              </p>
                            </>
                          )}
                          <p className="success-message mt-3">
                            Total data: {metaUpload.total_row}. <br />
                            Total new inserts: {metaUpload.inserted_rows}.<br />
                            Total updated: {metaUpload.updated_rows}.<br />
                            Total Deleted: {metaUpload.deleted_rows}.<br />
                            Notes: {metaUpload.notes === null ? '-' : (
                              <ol>
                                {metaUpload.notes.map(note => (
                                  <li>{note}</li>
                                ))}
                              </ol>
                            )}
                          </p>
                        </div>
                      )
                      }                              
                    </div>
                  ) : (
                    <div>
                    <Dropzone 
                      onDrop={handleFiles}
                      accept={[ ".xls", ".xlsx"]}
                    >
                      {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <figure className="dropzone-img">
                              <img src={fileImg} alt="" />
                            </figure>
                            <p>{lang("employee:text.dragHere")}</p>
                            <Button key="choose" type="default">
                              {lang("employee:button.chooseFile")}
                            </Button>
                            <Alert size="small" className="mt-2" message={lang("employee:text.makeSureResignEmploye")} type="warning" />              
                          </div>
                      )}
                      </Dropzone>

                    </div> 
                  )}
                </div>                      
                )}
            </section>
          </div>
        </Section>
      </Modal>
    );
  }

  export default AddEmployeeModal;