export const salaryOption = [
  { value: "nett", label: "Nett"},
  { value: "gross", label: "Gross"}
];

export const probationOption = [
  { value: "3", label: "3 months" },
  { value: "6", label: "6 months" },
  { value: "12", label: "12 months" },
  { value: "0", label: "No probation"}
];

export const cutOffOption = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];