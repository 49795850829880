import React, { useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  message
} from "antd";
import { Section } from "./style";
import { useTranslation } from "react-i18next";
import SuccessRegisterModal from "../../components/Modal/SuccessRegisterModal";
import partner from "../../repository/Partner";
import history from "../../routers/history";

function PartnershipForm(props) {
  const { t } = useTranslation();
  const lang = window.localStorage.getItem("language") || "en";
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [cp, setCp] = useState("");
  const [establishedIn, setEstablishedIn] = useState(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [employees, setEmployees] = useState(null);

  const [mandatoryName, setMandatoryName] = useState("");
  const [mandatoryCp, setMandatoryCp] = useState("");
  const [mandatoryPhone, setMandatoryPhone] = useState("");

  const validateName = value => {
    let status = value === "" ? "error" : "";
    setMandatoryName(status);
  }
  const validateCp = value => {
    let status = value === "" ? "error" : "";
    setMandatoryCp(status);
  }
  const validatePhone = value => {
    let status = value === "" ? "error" : "";
    setMandatoryPhone(status);
  }
  const handleClose = () => {
    setShow(false);
    history.push("/login");
  }
  const handleSubmit = () => {
    validateName(companyName);
    validateCp(cp);
    validatePhone(phone);

    if (!companyName || !cp || !phone) {
      return;
    } else {
      setLoading(true);
      let data = {
        company_name: companyName,
        contact_person: cp,
        established_in: establishedIn,
        phone_number: phone,
        address: address,
        number_of_employees: parseInt(employees)
      }
      partner
        .postNewPartner({ params: data })
        .then(res => {
          if (res.status === 200) {
            setShow(true);
          } else {
            message.error(res.message[lang]);
          }
        });
      setLoading(false);
    }
  }

  return (
    <Section>
      <div className="kwRegisterWrapper">
        <Card>
          <div className="kwRegisterWrapper__top-section">
            <h3 className="section-title">
              {t("common:title.introducingKoingaji")}
            </h3>
            <p>
              <b>{t("common:text.koinGaji")}</b> {t("common:text.isProduct")} {" "}
              <b>{t("common:text.koinworks")}</b> {t("common:text.toProvideSalary")}
            </p>
            <p>
              {t("common:text.a")} <b>{t("common:text.salaryAdvance")}</b> {" "}
              {t("common:text.isRealLoan")}
            </p>
          </div>
          <div className="kwRegisterWrapper__form-section">
            <h3 className="section-title">
              {t("common:title.companyDetails")}
            </h3>
            <Form>
              <Row>
                <Col xs={24} lg={16}>
                  <Form.Item
                    validateStatus={mandatoryName}
                  >
                  <span className="input-label">
                    {t("common:label.companyName")}
                  </span> 
                  <Input
                    size="large"
                    type="text"
                    id="companyName"
                    value={companyName}
                    placeholder={t("common:label.companyName")}
                    onChange={
                      ({ target }) => setCompanyName(target.value)
                    }
                  />
                  {mandatoryName && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryCp}
                >
                  <span className="input-label">
                    {t("common:label.contactPerson")}
                  </span> 
                  <Input
                    size="large"
                    type="text"
                    id="contactPerson"
                    value={cp}
                    placeholder={t("common:label.contactPerson")}
                    onChange={
                      ({ target }) => setCp(target.value)
                    }
                  />
                  {mandatoryCp && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("common:label.establishedIn")}
                  </span> 
                  <DatePicker
                    className="w-100"
                    id="establishedIn"
                    onChange={
                      (date, string) => setEstablishedIn(string)
                    }
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={mandatoryPhone}
                >
                  <span className="input-label">
                    {t("common:label.phoneNumber")}
                  </span> 
                  <Input
                    size="large"
                    type="tel"
                    id="phoneNumber"
                    value={phone}
                    placeholder={t("common:label.phoneNumber")}
                    onChange={
                      ({ target }) => setPhone(target.value)
                    }
                  />
                  {mandatoryPhone && (
                    <span className="error-validation">
                      {t("employee:text:requiredField")}
                    </span>
                  )}
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("common:label.address")}
                  </span> 
                  <Input
                    size="large"
                    type="text"
                    id="email"
                    value={address}
                    placeholder={t("common:label.address")}
                    onChange={
                      ({ target }) => setAddress(target.value)
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <span className="input-label">
                    {t("common:label.numberOfEmployee")}
                  </span> 
                  <Input
                    size="large"
                    type="text"
                    id="employeeTotal"
                    value={employees}
                    placeholder={t("common:label.numberOfEmployee")}
                    onChange={
                      ({ target }) => setEmployees(target.value)
                    }
                  />
                </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Button
                    type="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {t("common:button.sendApp")}
                  </Button>
                </Col>
              </Row>
                          
            </Form>
          </div>
        </Card>

        <SuccessRegisterModal 
          visible={show}
          width={400}
          lang={t}
          onClose={handleClose}
        />
      </div>
    </Section>
  );
};

export default PartnershipForm;
