import React, { useReducer, useContext, Fragment } from "react";
import cookie from "react-cookies";
import authentication from "../../repository/Authentication";
import upload from "../../repository/Upload";
import employee from "../../repository/Employee";
import partner from "../../repository/Partner";
// import ClevertapReact from "../../library/clevertap";
import clevertap from 'clevertap-web-sdk';
import { expiredToken } from "../../library/auth";

const CreateRootContext = React.createContext();

const RootContext = props => {
  const token = cookie.load("token");
  const flag = window.localStorage.getItem("language") || "en";
  // const authenticated = JSON.parse(window.localStorage.getItem("authenticated"));

  const initialState = {
    isAuthenticated: token ? true : false,
    data: {
      fullname: "",
      phone_area: "",
      phone_number: "",
      email: ""
    },
    status: "",
    authError: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "authentication":
        return {
          ...state,
          status: action.payload,
          data: action.payload.data,
          isAuthError: false,
          isAuthenticated: true,
          authentication: action.payload.data
        };
      case "authentication-error":
        return {
          ...state,
          authError: action.payload.message,
          isAuthError: true
        };
      case "get-partner-account":
        return {
          ...state,
          partner: action.payload.data,
          isAuthError: false,
          isAuthenticated: true
        };
      case "get-partner-account-error":
        return {
          ...state,
          authError: action.payload.message,
          isAuthError: true,
          isAuthenticated: false
        }
      case "bulk-employee-success":
        return {
          ...state,
          isBulkedEmployee: true,
          metaUpload: action.payload
        };
      case "upload-employee-file-error":
        return {
          ...state,
          isBulkedEmployee: false,
          bulkEmployeeError: action.payload.message
        };
      case "bulk-employee-error":
        return {
          ...state,
          isAddedEmployee: false,
          bulkEmployeeError: action.payload
        };
      case "logout":
        return {
          ...state,
          isAuthenticated: false
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const authUser = async (
    body,
    actionChangeAlert,
    actionChangeLoadingShow,
    actionShowModal
  ) => {
    const responseAuth = await authentication.checkAuthentication(
      {
        params: body,
        actionChangeAlert: actionChangeAlert,
        actionChangeLoadingShow: actionChangeLoadingShow
      }
    );
    if (      
      responseAuth &&
      responseAuth.status === 200
    ) {
      cookie.save(
        "token",
        responseAuth.data.access_token, 
        {
          path: '/', 
          maxAge: expiredToken(responseAuth.data.access_token)
        }
      );
      // dispatch({
      //   type: "authentication",
      //   payload: responseAuth
      // });
      getPartnerAccount(
        body,
        actionChangeAlert,
        actionChangeLoadingShow,
        actionShowModal
      );
    } else if (responseAuth) {
      actionChangeAlert(true);
      actionChangeLoadingShow(false);

      dispatch({
        type: "authentication-error",
        payload: responseAuth
      });
    }
  };
  // process of upload file to the server
  const uploadEmployeeFile = async (
    body,
    id,
    actionChangeAlert,
    actionChangeLoadingShow,
    actionChangeLoadingMessage,
    actionUpdateData,
    actionCloseModal,
    actionChangeUploadedStatus,
    actionChangeButtonModalText,
    actionSetUploadData,
    actionDeleteUploadData
  ) => {
    actionChangeLoadingShow(true);
    actionChangeLoadingMessage("Uploading file to server...");
    const responseUpload = await upload.uploadFile(body); //function upload file
    if (
      responseUpload &&
      responseUpload.code === 200
    ) {
      let data = {
        vendor_id: id,
        file_url: responseUpload.data.uploaded_file.replace('.http://','.')
      }
      // validate data user
      const responseValidate = await upload.validateData({params:data}); 
      if (
        responseValidate &&
        responseValidate.status === 200
      ) {
        if(responseValidate.data.false_file_url !== ""){
          // handleFailedUpload(responseValidate.data.false_file_url);
          actionSetUploadData(responseValidate.data)
          actionChangeLoadingShow(false);
          actionChangeAlert(true);
        } else {
          actionChangeLoadingMessage("Processing data employee...");
          bulkEmployee( // calling function bulkEmployee
            {
              vendor_id: id,
              correct_rows: responseValidate.data.correct_rows
            },
            actionChangeAlert,
            actionChangeLoadingShow,
            actionChangeLoadingMessage,
            actionUpdateData,
            actionCloseModal,
            actionChangeUploadedStatus,
            actionChangeButtonModalText,
            actionDeleteUploadData
          );  
        }

      } else {
        actionChangeLoadingShow(false);
        actionChangeAlert(true);
        actionChangeUploadedStatus(false);
        let msg = "";
        if (responseValidate?.errors && responseValidate?.errors.length > 0) {
          msg = responseValidate?.errors[0].userMessage;
        } else {
          msg = responseValidate?.message[flag];
        }
        dispatch({
          type: "bulk-employee-error",
          payload: msg
        });
      }    
    } else if (responseUpload) {
      actionChangeLoadingShow(false);
      actionChangeLoadingMessage("");
      actionChangeAlert(true);
      actionChangeUploadedStatus(false);
      dispatch({
        type: "upload-employee-file-error",
        payload: responseUpload
      });
    }
  };

  //process file to bulk employee
  const bulkEmployee = async (
    body,
    actionChangeAlert,
    actionChangeLoadingShow,
    actionChangeLoadingMessage,
    actionUpdateData,
    actionCloseModal,
    actionChangeUploadedStatus,
    actionChangeButtonModalText,
    actionDeleteUploadData
  ) => {
    const responseBulk = await employee.postBulkUploadV2(
      {
        params: body
      }
    );
    if (
      responseBulk &&
      responseBulk.status === 200
    ) {
      partner
      .putMonthlyUpdateEmployee({
        id: body.vendor_id,
        status: "true"
      })
      .then(res => {
        if (res.status === 200) {
          let vendor = JSON.parse(window.localStorage.getItem("vnd"));
          vendor.monthly_update = "true";
          window.localStorage.setItem("vnd",
            JSON.stringify(vendor)
          );
          window.localStorage.setItem("ep_update",
            JSON.stringify({
              vendor: vendor.vid
            })
          );

          let currentDate = new Date();
          let dataVendor = JSON.parse(window.localStorage.getItem("vnd"));

          let eventData = {
            "Source": "List Menu",
            "Category": "New List",
            "Partner Name": dataVendor.name,
            "Date": currentDate,
            "Formatted Date": currentDate.getDate() + '/' +
              (currentDate.getMonth()+1) + '/' +
              currentDate.getFullYear()
          };
          clevertap.onUserLogin.push({
            "Site": {
              "Name": dataVendor.name,
              "Email": dataVendor.email
            }
          });
          clevertap.event.push("Update Employee List", eventData);

        }
      });

      actionChangeLoadingShow(false);
      actionChangeLoadingMessage("");
      actionUpdateData();
      actionChangeAlert(true);
      actionChangeButtonModalText();
      actionDeleteUploadData();
      // setTimeout(function(){
      //   actionCloseModal();
      // }, 2000); 
      dispatch({
        type: "bulk-employee-success",
        payload: responseBulk.meta
      });
    } else if (responseBulk) {
      actionChangeLoadingShow(false);
      actionChangeAlert(true);
      actionChangeUploadedStatus(false);
      actionDeleteUploadData();
      let msg = "";
      if (responseBulk.errors && responseBulk.errors.length > 0) {
        msg = responseBulk.errors[0].userMessage;
      } else {
        msg = responseBulk.message[flag];
      }
      dispatch({
        type: "bulk-employee-error",
        payload: msg
      });
    }
  };

  const getPartnerAccount = async (
    body,
    actionChangeAlert,
    actionChangeLoadingShow,
    actionShowModal
  ) => {
    const responseAccount = await partner.getPartnerProfile({});
    if (
      responseAccount &&
      responseAccount.status === 200
    ) {
      let partner = JSON.stringify({
        vid: responseAccount.data.vendor_id,
        name: responseAccount.data.company_name,
        email: body.email,
        monthly_update : responseAccount.data.company_monthly_employee_update,
        cut_off: parseInt(
          responseAccount.data.payroll_cut_off_date
        ),
        start: responseAccount.data.payroll_start_date,
        end: responseAccount.data.payroll_end_date
      });
      window.localStorage.setItem("vnd", partner);
      actionChangeLoadingShow(false);
      actionShowModal(true);
      dispatch({
        type: "get-partner-account",
        payload: responseAccount
      });
    } else if (responseAccount) {
      actionChangeAlert(true);
      actionChangeLoadingShow(false);
      cookie.remove("token", { path: '/' });
      dispatch({
        type: "get-partner-account-error",
        payload: responseAccount
      });
    }
  };
  const logout = () => {
    cookie.remove("token", { path: '/' });
    localStorage.clear();
    dispatch({
      type: "logout"
    });
  };

  return (
    <Fragment>
      <CreateRootContext.Provider
        value={{
          ...state,
          authenticationUser: (
            body,
            actionChangeAlert,
            actionChangeLoadingShow,
            actionShowModal
          ) => {
            authUser(
              body,
              actionChangeAlert,
              actionChangeLoadingShow,
              actionShowModal
            );
          },
          bulkUploadEmployee: (
            body,
            id,
            actionChangeAlert,
            actionChangeLoadingShow,
            actionChangeLoadingMessage,
            actionUpdateData,
            actionCloseModal,
            actionChangeUploadedStatus,
            actionChangeButtonModalText,
            actionSetUploadData,
            actionDeleteUploadData
          ) => {
            uploadEmployeeFile(
              body,
              id,
              actionChangeAlert,
              actionChangeLoadingShow,
              actionChangeLoadingMessage,
              actionUpdateData,
              actionCloseModal,
              actionChangeUploadedStatus,
              actionChangeButtonModalText,
              actionSetUploadData,
              actionDeleteUploadData
            );
          },
          uploadFilteredEmployee: (
            body,
            actionChangeAlert,
            actionChangeLoadingShow,
            actionChangeLoadingMessage,
            actionUpdateData,
            actionCloseModal,
            actionChangeUploadedStatus,
            actionChangeButtonModalText,
            actionDeleteUploadData
          ) => {
            bulkEmployee(
              body,
              actionChangeAlert,
              actionChangeLoadingShow,
              actionChangeLoadingMessage,
              actionUpdateData,
              actionCloseModal,
              actionChangeUploadedStatus,
              actionChangeButtonModalText,
              actionDeleteUploadData
            )
          },
          handlePartnerAccount: (
            body,
            actionChangeAlert,
            actionChangeLoadingShow
          ) => {
            getPartnerAccount(
              body,
              actionChangeAlert,
              actionChangeLoadingShow
            );
          },
          handleLogout: () => {
            logout();
          },          
          history: props.history,
          match: props.match
        }}
      >
        {props.children}
      </CreateRootContext.Provider>
    </Fragment>
  );
};
export const useRootContext = () => useContext(CreateRootContext);
export default RootContext;
